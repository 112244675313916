<template>
  <f7-page no-toolbar no-swipeback name="change-security-pwd" class="page-change-security-pwd">
    <f7-navbar :title="IsBinding ? $t('change_security_pwd_0001') : $t('change_security_pwd_0016')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-change-security-pwd">
      <div class="form">
        <div class="form-item" v-show="IsBinding">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("change_security_pwd_0004") }}</div>
            <div class="item-input-wrap">
              <input type="password" :placeholder="$t('change_security_pwd_0005')" v-model="updateSecPwdForm.OldPassword" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("change_security_pwd_0006") }}</div>
            <div class="item-input-wrap">
              <input type="password" :placeholder="$t('change_security_pwd_0007')" v-model="updateSecPwdForm.NewPassword" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("change_security_pwd_0008") }}</div>
            <div class="item-input-wrap">
              <input type="password" :placeholder="$t('change_security_pwd_0009')" v-model="updateSecPwdForm.ConfirmPassword" />
            </div>
          </div>
        </div>
      </div>
    </f7-block>
    <div class="list inset button-save">
      <ul>
        <li>
          <a href="#" class="list-button color-black" @click="updateSecSubmit">{{ $t("change_security_pwd_0010") }}</a>
        </li>
      </ul>
    </div>
  </f7-page>
</template>
<script>
import { checkSecurePwdStatus, updateSecPwd } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      IsBinding: false,
      updateSecPwdForm: {
        SureName: "",
        OldPassword: "",
        NewPassword: "",
        ConfirmPassword: "",
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    updateSecSubmit() {
      // let sureNameReg = /^[\u4E00-\u9FA5\uF900-\uFA2D|\·]+$/;
      let pwdReg = /^\d{6}$/;
      const self = this;
      // if (
      //   !self.updateSecPwdForm.SureName ||
      //   !sureNameReg.test(self.updateSecPwdForm.SureName)
      // ) {
      //   self.$f7.dialog.alert(this.$t('change_security_pwd_0011'), this.$t('common_0004'));
      //   return false;
      // }
      if (this.IsBinding) {
        if (!self.updateSecPwdForm.OldPassword) {
          self.$f7.dialog.alert(this.$t("change_security_pwd_0017"), this.$t("common_0004"));
          return false;
        }
      }
      if (!self.updateSecPwdForm.NewPassword || !pwdReg.test(self.updateSecPwdForm.NewPassword)) {
        self.$f7.dialog.alert(this.$t("change_security_pwd_0013"), this.$t("common_0004"));
        return false;
      }
      if (self.updateSecPwdForm.NewPassword != self.updateSecPwdForm.ConfirmPassword) {
        self.$f7.dialog.alert(this.$t("change_security_pwd_0014"), this.$t("common_0004"));
        return false;
      }
      const d = {
        data: JSON.stringify({
          OldPassword: self.updateSecPwdForm.OldPassword,
          NewPassword: self.updateSecPwdForm.NewPassword,
          SureName: self.updateSecPwdForm.SureName,
        }),
      };
      updateSecPwd(d).then((data) => {
        if (data.Code === "NoError") {
          self.IsBinding = true;
          self.$f7.dialog.alert(this.$t("change_security_pwd_0015"), this.$t("common_0004"));
          self.updateSecPwdForm = {
            SureName: "",
            OldPassword: "",
            NewPassword: "",
            ConfirmPassword: "",
          };
          this.$f7router.navigate("/member-center/");
        } else {
          self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
        }
      });
    },
  },
  created() {
    checkSecurePwdStatus().then((data) => {
      if (data.Code === "NoError") {
        this.IsBinding = data.Data.IsBinding;
      } else {
        self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
      }
    });
  },
  mounted() {},
  updated() {},
};
</script>
<style lang="less" scoped>
.page-change-security-pwd {
  --f7-page-toolbar-bottom-offset: 0;
  .block-change-security-pwd {
    margin: 5px 0 10px;
    padding: 0 10px;
    .form {
      .form-item {
        .item-inner {
          .item-title {
            color: #464646;
            font-size: 14px;
          }
          .item-input-wrap {
            // width: 100%;
            background: #fafafa;
            border: 1px solid #1b2a3b10;
            padding: 10px;
            border-radius: 6px;
            margin: 2px 0 10px;
            input {
              width: 100%;
            }
          }
          .smart-select .item-after {
            max-width: unset;
          }
        }
      }
    }
    .list-change-security-pwd {
      margin: 0;
      ul {
        background-color: transparent;
        .item-input {
          .item-inner {
            .item-title {
              padding-top: 8px;
              color: #464646;
              width: 26%;
            }
            input {
              color: #464646;
              font-size: 14px;
            }
          }
          .item-inner:after {
            height: 0;
          }
        }
      }
      ul:before,
      ul:after {
        height: 0;
      }
    }
  }
  .button-save {
    margin: 10px 12px;

    ul {
      background: #9C865A;
      li {
        a {
          color: #fff;
        }
      }
    }
  }
}
</style>
