<template>
  <f7-page no-toolbar class="page-activity-center" name="activity-center" @page:init="onPageInit" @page:beforein="onPageBeforeIn">
    <!-- Top Navbar -->
    <f7-navbar back-link :sliding="false">
      <f7-nav-title sliding>
        {{ $t('activity_center_0001') }}
      </f7-nav-title>
      <f7-nav-right v-if="getLoginState" class="nav-balance">
        <!-- <f7-link @click="openBalancePopup">
          <img class="btn-dowload" src="static/images/yt999/balance.png" height="22">
        </f7-link> -->
      </f7-nav-right>
    </f7-navbar>
    <van-tabs v-model="tabActiveId" class="tabs-activity" @change="changeTab" color="#9C865A" title-active-color="#9C865A" sticky swipeable line-width="50" offset-top="45">
      <van-tab v-for="item of activityTabs" :key="item.MissionID" :name="item.MissionID" class="tab-content" :title-class="'title-' + item.MissionID">
        <template #title>
          <span>{{ missionNameMaps[item.MissionType] }}{{ getTaskRange(item) }}</span>
        </template>
        <component v-if="tabActiveId == item.MissionID" :is="item.MissionType" :item="item" @receive="loadActivityList()" @refreshPoint="setPoint" />
      </van-tab>
    </van-tabs>
  </f7-page>
</template>
<script>
import { getCookie, setStore, removeStore, getStore } from "@/config/utils";
import { mapGetters, mapMutations } from "vuex";
import http from "@/axios/http";
import TotalDepositBonus from './component/TotalDepositBonus.vue'
import TotalTurnoverBonus from './component/TotalTurnoverBonus.vue'
import WinLoseBonus from './component/WinLoseBonus.vue'
import InviteMemberToOpenBonusBox from './component/InviteMemberToOpenBonusBox.vue'
import TotalTurnoverToPlayTurnTable from './component/TotalTurnoverToPlayTurnTable.vue'
export default {
  components: {
    TotalDepositBonus,
    TotalTurnoverBonus,
    WinLoseBonus,
    InviteMemberToOpenBonusBox,
    TotalTurnoverToPlayTurnTable
  },
  data() {
    return {
      tabActiveId: '',
      activityTabs: []
    };
  },
  computed: {
    ...mapGetters(['getLoginState', 'missionNameMaps']),
  },
  methods: {
    ...mapMutations(["SETMERCHAT_INFO", "SETALLBALANCES", "SETFIRSTLOADAPP"]),
    onPageInit(e, page) {
      // this.tabActiveId = Number(page.route.params.missionId)
      this.tabActiveId = Number(this.$f7route.params.missionId)
      this.loadActivityList()
    },
    onPageBeforeIn() {
    },
    async loadActivityList() {
      const res = await http.post('/act_api/missiondetaillistget')
      if (res.Code == 'NoError' && res.Data) {
        const data = res.Data
        this.tabActiveId = this.tabActiveId || data[0].MissionID
        const activiys = data.reduce((prev, curr) => {
          if (!prev[curr.MissionID]) {
            prev[curr.MissionID] = {
              SettingList: [curr],
              ...curr
            }
          } else {
            prev[curr.MissionID].SettingList.push(curr)
          }
          return prev
        }, {})

        this.activityTabs = Object.values(activiys).sort((a, b) => {
          return a.SortIndex - b.SortIndex
        })
      }
    },
    changeTab(id) {
      // this.tabActiveId = id
    },
    getTaskRange(item) {
      // console.log(item.MissionTimeRangeType);
      if (['InviteMemberToOpenBonusBox', 'TotalTurnoverToPlayTurnTable'].includes(item.MissionType)) return ''
      return ['Daily', 'Weekly'].includes(item.MissionTimeRangeType) ? `(${ this.$t(`activity_center_${item.MissionTimeRangeType}`) })` : ''
    },
    setPoint({ missionId, point }) {
      let item = this.activityTabs.find(item => item.MissionID == missionId)
      item.OwnQuantity = point
    }
  },
  created() { },
  mounted() { },
};
</script>
<style lang="less" scoped>
.page-activity-center {
}
</style>