<template>
  <f7-page no-toolbar no-swipeback name="vip-monthly-salary" class="page-vip-monthly-salary">
    <f7-navbar :title="$t('v1_vms_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-card class="card-user-info" v-if="userInfo.UserName">
      <f7-card-content>
        <f7-row no-gap class="row-level">
          <f7-col width="35" class="user-vip-img text-center">
            <img :src="`../../static/images/yt999/vip/${vipLevelCode.toLowerCase()}.png`" alt />
          </f7-col>
          <f7-col width="35" class="col-name">
            <div class="user-name">{{ userInfo.UserName.split(".")[1] }}</div>
            <div class="user-level">{{ userInfo.VipLevelName }}</div>
          </f7-col>
          <f7-col width="30"></f7-col>
        </f7-row>
        <div class="position-user-level">{{ userInfo.VipLevelName }}</div>

        <div class="panel-login-info">
          <f7-row no-gap>
            <f7-col width="30" class="text-left">{{ $t("v1_vms_0002") }}:</f7-col>
            <f7-col width="70" class="text-left">{{ monthlySalaryInfo.EnableBonusButton ? monthlySalaryInfo.SalaryRemark : "--" }}</f7-col>
          </f7-row>
        </div>
      </f7-card-content>
    </f7-card>

    <f7-list inset class="button-salary" :class="{ 'disabled-status': !monthlySalaryInfo.EnableBonusButton }">
      <f7-list-button :title="$t('v1_vms_0003')" color="black" @click="salarySubmit"></f7-list-button>
    </f7-list>
    <f7-block class="block-remark" v-if="!monthlySalaryInfo.EnableBonusButton">
      <p>{{ monthlySalaryInfo.SalaryRemark }}</p>
    </f7-block>
    <f7-block class="block-tip">
      <p class="tip-title">{{ $t("common_0004") }}：</p>
      <p>1.&nbsp;{{ $t("v1_vms_0004") }}</p>
      <p>2.&nbsp;{{ $t("v1_vms_0005") }}</p>
      <p>3.&nbsp;{{ $t("v1_vms_0006") }}</p>
      <p>4.&nbsp;{{ $t("v1_vms_0007", [$f7.params.name]) }}</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { setCookie, getCookie, getStore, removeStore } from "../../../config/utils";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getUserInfo, getBalances, checkMonthlySalary, getMonthlySalary } from "../../../axios/api";
import common from "../../../service/common";

export default {
  components: {},
  props: {},
  data() {
    return {
      balances: {},
      monthlySalaryInfo: {},
      vipLevelCode: "",
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    ...mapActions(["getUserInfo"]),
    ...mapMutations(["SETALLBALANCES"]),
    balancesEvent() {
      const self = this;
      getBalances().then((data) => {
        if (data.Code === "NoError") {
          self.SETALLBALANCES(data.Data);
          self.balances = data.Data;
        }
      });
    },
    checkMonthlySalaryEvent() {
      const self = this;
      checkMonthlySalary().then((data) => {
        if (data.Code === "NoError") {
          self.monthlySalaryInfo = data.Data;
        }
      });
    },
    salarySubmit() {
      const self = this;
      if (!self.monthlySalaryInfo.EnableBonusButton) return;
      getMonthlySalary().then((data) => {
        self.$f7.dialog.alert(data.Data, this.$t("common_0004"), () => {
          self.checkMonthlySalaryEvent();
        });
      });
    },
  },
  created() {
    this.checkMonthlySalaryEvent();
    //this.balancesEvent();
    this.getUserInfo();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.page-vip-monthly-salary {
  .card-user-info {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    margin: var(--f7-margin-size-0);

    .card-content {
      width: var(--f7-size-percent-93);
      padding: var(--f7-padding-size-5);
      margin: 0 auto;
      height: 180px;
      background: url(../../../static/images/yt999/about_bg.png) no-repeat;
      background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
      margin-bottom: var(--f7-px-4);
      margin-top: var(--f7-px-4);
      border: 1px solid #e4e3e3;

      .row {
        align-items: center;
        padding: var(--f7-padding-size-6) 0;

        .user-vip-img {
          img {
            width: var(--f7-px-84);
            height: var(--f7-px-84);
          }
        }
        .col-name {
          margin-bottom: 10px;
        }
        .user-name {
          color: #464646;
          font-size: var(--f7-px-26);
        }

        .user-level {
          background: -webkit-linear-gradient(left, #3a60c1, #677bb3);
          background: -o-linear-gradient(right, #3a60c1, #677bb3);
          background: -moz-linear-gradient(right, #3a60c1, #677bb3);
          background: linear-gradient(to right, #3a60c1, #677bb3);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: #f1d49a;
          font-size: var(--f7-px-20);
          font-weight: bold;
        }
      }

      .row-level {
        border-bottom: 1px solid var(--f7-color-blue-keba);
      }

      .position-user-level {
        color: var(--f7-color-black);
        position: var(--f7-postion-absolute);
        right: 8px;
        top: var(--f7-px-13);
        font-size: var(--f7-px-14);
        font-weight: bold;
        transform: rotate(40deg);
        -ms-transform: rotate(40deg);
        -moz-transform: rotate(40deg);
        -webkit-transform: rotate(40deg);
        -o-transform: rotate(40deg);
      }

      .panel-login-info {
        margin: var(--f7-px-5) var(--f7-margin-size-10);
        color: #464646;
        font-size: var(--f7-px-16);

        i.f7-icons {
          font-size: var(--f7-px-16);
          color: var(--f7-color-gray-tint);
          margin-right: var(--f7-px-f5);
        }
      }
    }
  }
  .block-remark {
    margin: 10px 10px;
    padding: 0;
    p {
      color: #fd6666;
      font-size: 16px;
    }
  }
  .block-tip {
    margin: 20px 8px;
    padding: 0;
    p {
      color: #464646;
    }
    .tip-title {
      color: #464646;
    }
  }
}
</style>
