<template>
  <f7-page @page:init="onPageInit" @page:beforein="onPageBeforeIn" @page:beforeout="onPageBeforeOut" class="page-member-center" name="member-center">
    <f7-navbar :title="$t('member_center_0001')" :back-link="false" :no-hariline="true"></f7-navbar>
    <f7-card class="card-user-info" v-if="getLoginState">
      <f7-card-content>
        <div style="text-align: right;padding: 5px 10px;">
          <i class="f7-icons color-white" style="font-size:20px;margin-right: 10px;" @click="$f7router.navigate('/member-center/online-cs/')">headphones</i>
          <i class="f7-icons color-white" style="font-size:20px" @click="$f7router.navigate('/member-center/message-center/2/')">envelope</i>
        </div>

        <div class="panel-login-info vipsty">
          <div style="text-align: center;align-content: center;">
            <img src="../../static/images/yt999/vip/vip1.png" width="50" srcset="">
          </div>
          <div v-if="currVip.level==10">
            <p style="padding-top: 16px;">{{ $t("member_center_0055") }}</p>
          </div>
          <div v-else>
            <p>{{ $t("member_center_0050") }}<span style="color: black;">&nbsp;VIP{{ nextVip.level }}, </span>{{ $t("member_center_0051") }}&nbsp;<span style="color: black;">{{ (nextVip.deposit-userInfo.DepositTotalAmount) | formatCurrency  }}</span>
              <i class="f7-icons" style="color: #b79244;position: absolute;right: 13px;">chevron_right</i></p>
            <p>
              <span>{{ $t("member_center_0052") }}</span> 
              <f7-progressbar :progress="(userInfo.DepositTotalAmount / nextVip.deposit) * 100" class="tiao" />
              <span class="jdnum">{{userInfo.DepositTotalAmount | formatCurrency }} / {{ nextVip.deposit }}</span>
            </p>
          </div>
        </div>
      </f7-card-content>
    </f7-card>
    <f7-card class="card-no-login" v-if="!getLoginState">
      <f7-button outline href="/login/">{{ $t("member_center_0005") }}</f7-button>
    </f7-card>

    <div class="vipInfo">
      <div class="left">
        <div>
          <img src="../../static/images/yt999/vip/vip.png" width="50" srcset="">
        </div>
        <div>
          <p>{{ $t("member_center_0053") }}: {{ userInfo.UserName | formatUserName }}</p>
          <p>{{ $t("member_center_0054") }}: {{ userInfo.VipLevelName }}</p>
        </div>
      </div>
      <div class="right">
        <div class="cnlogo">
          <img src="../../static/images/yt999/home/ico/china.png" width="22" srcset="">
        </div>
        <div>
          <f7-link popup-open=".balanceInfo-popup" v-if="totalBalance > -1">
            <span class="totalBalance" style="color: #fff;font-size: 16px;">{{ userInfo.currencySymbol }}&nbsp;{{ totalBalance | formatCurrency }}</span>
            <i class="f7-icons" style="color: #fff;font-size: 20px;">chevron_right</i>
          </f7-link>
          <preloader :list="4" v-else></preloader>
        </div>
      </div>
    </div>

    <div class="row member-transfer">
      <div class="action col-25" @click="$f7router.navigate('/member-center/deposit/')">
        <a>
          <p>
            <img src="@/static/images/yt999/member-deposit.png" alt="DepositQA" />
          </p>
          <p>{{ $t("member_center_0046") }}</p>
        </a>
      </div>
      <div class="action col-25" @click="$f7router.navigate('/member-center/withdraw/')">
        <a>
          <p>
            <img src="@/static/images/yt999/member-withdraw.png" alt="Withdraw" />
          </p>
          <p>{{ $t("member_center_0047") }}</p>
        </a>
      </div>
      <div class="action col-25" @click="$f7router.navigate('/member-center/transfer/')">
        <a>
          <p>
            <img src="@/static/images/yt999/member-transfer.png" alt="Transfer" />
          </p>
          <p>{{ $t("member_center_0048") }}</p>
        </a>
      </div>
      <div class="action col-25" @click="$f7router.navigate('/member-center/vip-club/')">
        <a>
          <p>
            <img src="@/static/images/yt999/member-vip.png" alt="VIP" />
          </p>
          <p>{{ $t("member_center_0049") }}</p>
        </a>
      </div>
    </div>
    <!-- <div class="buttons-tab tabs">
      <f7-link tab-link="#tab-accountCenter" tab-link-active icon="iconfont icon-huiyuanvip" :text="$t('member_center_0006')"></f7-link>
      <f7-link tab-link="#tab-selfHelpPrefer" icon="iconfont icon-mendianzhuanxiangshenhe" :text="$t('member_center_0007')"></f7-link>
      <f7-link tab-link="#tab-accountList" icon="iconfont icon-qingdan" :text="$t('member_center_0008')"></f7-link>
      <f7-link tab-link="#tab-personalInfo" icon="iconfont icon-gerenziliao1" :text="$t('member_center_0009')"></f7-link>
      <f7-link tab-link="#tab-message">
        <f7-icon icon="iconfont icon-youxiang">
          <f7-badge color="red" v-if="userMessgaeList.length > 0"></f7-badge>
        </f7-icon>
        <span class="tabbar-label">{{ $t("member_center_0010") }}</span>
      </f7-link>
    </div> -->
    <f7-tabs class="tabs-member-center">
      <f7-tab @tab:show="tabInit(1)" id="tab-accountCenter" class="page-content" tab-active>
        <f7-block>
          <f7-list style="background: #fff;margin: 10px;">
            <f7-list-item link="/member-center/referrer/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">person_2_alt</i>
              <div class="item-title">{{$t('member_center_0011')}}</div>
            </f7-list-item>
            <f7-list-item link="/member-center/winloserp/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">doc_text_fill</i>
              <div class="item-title">{{$t('member_center_0012')}}</div>
            </f7-list-item>
            <f7-list-item link="/member-center/sign-in/"  >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">pencil_circle_fill</i>
              <div class="item-title">{{$t('member_center_0020')}}</div>
            </f7-list-item>
            <!-- <f7-list-item link="/member-center/buffet-promotion/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">capslock_fill</i>
              <div class="item-title">{{$t('member_center_0024')}}</div>
            </f7-list-item> -->
            <f7-list-item link="/member-center/point-center/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">layers_alt_fill</i>
              <div class="item-title">{{$t('member_center_0025')}}</div>
            </f7-list-item>
            <f7-list-item link="/member-center/activities-search/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">checkmark_square_fill</i>
              <div class="item-title">{{$t('member_center_0027')}}</div>
            </f7-list-item>
            <f7-list-item link="/member-center/deposit-search/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">square_favorites_alt_fill</i>
              <div class="item-title">{{$t('member_center_0028')}}</div>
            </f7-list-item>
            <f7-list-item link="/member-center/withdraw-search/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">square_favorites_fill</i>
              <div class="item-title">{{$t('member_center_0029')}}</div>
            </f7-list-item>
            <f7-list-item link="/member-center/funds-search/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">arrow_right_arrow_left_square_fill</i>
              <div class="item-title">{{$t('member_center_0030')}}</div>
            </f7-list-item>
            <f7-list-item link="/member-center/integral-search/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">arrow_right_arrow_left_square_fill</i>
              <div class="item-title">{{$t('member_center_0031')}}</div>
            </f7-list-item>
            <f7-list-item link="/member-center/activity-search/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">arrow_right_arrow_left_square_fill</i>
              <div class="item-title">{{$t('member_center_0032')}}</div>
            </f7-list-item>
            <f7-list-item link="/member-center/betting-search/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">arrow_right_arrow_left_square_fill</i>
              <div class="item-title">{{$t('member_center_0033')}}</div>
            </f7-list-item>
            <f7-list-item link="/member-center/member-info/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">person_crop_rectangle_fill</i>
              <div class="item-title">{{$t('member_center_0034')}}</div>
            </f7-list-item>
            <f7-list-item link="/member-center/bank-info/1/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">building_columns_fill</i>
              <div class="item-title">{{$t('member_center_0035')}}</div>
            </f7-list-item>
            <f7-list-item link="/member-center/change-login-pwd/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">lock_shield_fill</i>
              <div class="item-title">{{$t('member_center_0043')}}</div>
            </f7-list-item>
            <f7-list-item link="/member-center/change-security-pwd/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">lock_shield_fill</i>
              <div class="item-title">{{$t('member_center_0044')}}</div>
            </f7-list-item>
            <f7-list-item link="/member-center/message-center/1/" >
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">speaker_2_fill</i>
              <div class="item-title">{{$t('member_center_0037')}}</div>
            </f7-list-item>
            <f7-list-item link="/member-center/message-center/2/"  :badge="userMessgaeList.length">
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">ellipses_bubble_fill</i>
              <div class="item-title">{{$t('message_center_0003')}}</div>
            </f7-list-item>
            <f7-list-item link="#" @click="logout" :href="false"  v-if="getLoginState">
              <i class="f7-icons" style="color: #9C865A;font-size: 26px;margin-right: 15px;">square_arrow_right_fill</i>
              <div class="item-title">{{$t('member_center_0019')}}</div>
            </f7-list-item>
          </f7-list>
        </f7-block>
      </f7-tab>
      <f7-tab @tab:show="tabInit(2)" id="tab-selfHelpPrefer" class="page-content">
        <f7-block>
          <f7-list>
            <f7-list-item link="/member-center/sign-in/" :title="$t('member_center_0020')" class="icon-animation">
              <f7-icon slot="media" icon="iconfont icon-qiandao"></f7-icon>
            </f7-list-item>
            <!-- <f7-list-item link="/member-center/vip-monthly-salary/" :title="$t('member_center_0021')" class="icon-animation">
              <f7-icon slot="media" icon="iconfont icon-a-huiyuanVIPVIPka"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/vip-birthday-gift/" :title="$t('member_center_0022')" class="icon-animation">
              <f7-icon slot="media" icon="iconfont icon-a-huiyuanVIP"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/vip-holiday-gift/" :title="$t('member_center_0023')" class="icon-animation">
              <f7-icon slot="media" icon="iconfont icon-Giftliwu1"></f7-icon>
            </f7-list-item> -->
            <!-- <f7-list-item link="/member-center/buffet-promotion/" :title="$t('member_center_0024')" class="icon-animation">
              <f7-icon slot="media" icon="iconfont icon-reshengji"></f7-icon>
            </f7-list-item> -->
            <f7-list-item link="/member-center/point-center/" :title="$t('member_center_0025')" class="icon-animation">
              <f7-icon slot="media" icon="iconfont icon-jifen"></f7-icon>
            </f7-list-item>
            <!-- <f7-list-item link="/member-center/daily-task-search/" :title="$t('member_center_0026')">
              <f7-icon slot="media" icon="iconfont icon-check"></f7-icon>
            </f7-list-item> -->
            <f7-list-item link="/member-center/activities-search/" :title="$t('member_center_0027')">
              <f7-icon slot="media" icon="iconfont icon-check"></f7-icon>
            </f7-list-item>
          </f7-list>
        </f7-block>
      </f7-tab>
      <f7-tab @tab:show="tabInit(3)" id="tab-accountList" class="page-content">
        <f7-block>
          <f7-list>
            <f7-list-item link="/member-center/deposit-search/" :title="$t('member_center_0028')">
              <f7-icon slot="media" icon="iconfont icon-churukujilu"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/withdraw-search/" :title="$t('member_center_0029')">
              <f7-icon slot="media" icon="iconfont icon-churukujilu"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/funds-search/" :title="$t('member_center_0030')">
              <f7-icon slot="media" icon="iconfont icon-churukujilu"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/integral-search/" :title="$t('member_center_0031')">
              <f7-icon slot="media" icon="iconfont icon-jifenmingxi1"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/activity-search/" :title="$t('member_center_0032')">
              <f7-icon slot="media" icon="iconfont icon-Giftliwu1"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/betting-search/" :title="$t('member_center_0033')">
              <f7-icon slot="media" icon="iconfont icon-jilu"></f7-icon>
            </f7-list-item>
          </f7-list>
        </f7-block>
      </f7-tab>
      <f7-tab @tab:show="tabInit(4)" id="tab-personalInfo" class="page-content">
        <f7-block>
          <f7-list>
            <f7-list-item link="/member-center/member-info/" :title="$t('member_center_0034')">
              <f7-icon slot="media" icon="iconfont icon-yonghuxinxi"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/bank-info/1/" :title="$t('member_center_0035')">
              <f7-icon slot="media" icon="iconfont icon-yinhangqia"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/change-login-pwd/" :title="$t('member_center_0043')">
              <f7-icon slot="media" icon="iconfont icon-xiugaimima"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/change-security-pwd/" :title="$t('member_center_0044')">
              <f7-icon slot="media" icon="iconfont icon-xiugaimima"></f7-icon>
            </f7-list-item>
            <!-- <f7-list-item link="/member-center/set-encrypted/" title="设置密保">
              <f7-icon slot="media" icon="iconfont icon-yanzhengma1"></f7-icon>
            </f7-list-item> -->
          </f7-list>
        </f7-block>
      </f7-tab>
      <f7-tab @tab:show="tabInit(5)" id="tab-message" class="page-content">
        <f7-block>
          <f7-list>
            <f7-list-item link="/member-center/message-center/1/" :title="$t('member_center_0037')">
              <f7-icon slot="media" icon="iconfont icon-gonggao"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/message-center/2/" :title="$t('message_center_0003')" :badge="userMessgaeList.length">
              <f7-icon slot="media" icon="iconfont icon-a-yonghuxinxigerenziliao"></f7-icon>
            </f7-list-item>
          </f7-list>
        </f7-block>
      </f7-tab>
    </f7-tabs>

    <f7-popup v-show="showBalanceInfoPopup" class="balanceInfo-popup" :opened="balancePopupOpened" @popup:open="initBalanceInfo" @popup:close="closedBalanceInfo">
      <f7-page>
        <f7-navbar :title="$t('member_center_0004')">
          <f7-nav-right>
            <f7-link popup-close>
              <i class="f7-icons">multiply_circle</i>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <f7-row no-gap class="block-balance">
            <f7-col class="text-left">{{ $t("member_center_0038") }}</f7-col>
            <f7-col class="text-right">{{ userInfo.currencySymbol }}{{ totalBalance | formatCurrency }}</f7-col>
          </f7-row>
          <f7-row no-gap class="block-balance">
            <f7-col class="text-left">{{ $t("member_center_0039") }}</f7-col>
            <f7-col class="text-right">{{ userInfo.currencySymbol }}{{ walletBalance | formatCurrency }}</f7-col>
          </f7-row>
          <f7-row no-gap class="block-balance">
            <f7-col class="text-left">{{ $t("member_center_0040") }}</f7-col>
            <f7-col class="text-right">{{ userInfo.currencySymbol }}{{ paddingBalance | formatCurrency }}</f7-col>
          </f7-row>
          <f7-row no-gap class="block-sign">
            <f7-col class="text-left">{{ $t("member_center_0041") }}</f7-col>
            <f7-col class="text-right">{{ userInfo.currencySymbol }}{{ userSignAmount | formatCurrency }}</f7-col>
          </f7-row>
          <f7-row no-gap class="block-point">
            <f7-col class="text-left">{{ $t("member_center_0042") }}</f7-col>
            <f7-col class="text-right">{{ currentBonusPoint | formatCurrency }}</f7-col>
          </f7-row>
        </f7-block>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<script>
import preloader from "../template/preloader";
import api from "../../config/api";
import common from "../../service/common";
import { setCookie, getCookie, getStore, removeStore, setStore } from "../../config/utils";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getUserInfo, getBalances, logout, checkBonusPointWallet, userNameSignindetailDay, getUserNewsList } from "../../axios/api";
import http from "../../axios/http";
import { userInfo } from "os";

export default {
  components: {
    preloader,
  },
  props: {},
  data() {
    return {
      totalBalance: -1,
      paddingBalance: 0,
      walletBalance: 0,
      currentBonusPoint: 0,
      userSignAmount: 0,
      balancePopupOpened: false,
      userMessgaeList: [],
      showBalanceInfoPopup: true,
      privileges: {
        VIP0: { level: 0, salary: 0, upgradeBonus: 0, birthdayGift: 0, holidayGift: 0, signInBonus: 3, deposit: 0 },
        VIP1: { level: 1, salary: 38, upgradeBonus: 88, birthdayGift: 0, holidayGift: 0, signInBonus: 3, deposit: 10000 },
        VIP2: { level: 2, salary: 88, upgradeBonus: 188, birthdayGift: 0, holidayGift: 0, signInBonus: 5, deposit: 80000 },
        VIP3: { level: 3, salary: 168, upgradeBonus: 288, birthdayGift: 0, holidayGift: 0, signInBonus: 5, deposit: 200000 },
        VIP4: { level: 4, salary: 388, upgradeBonus: 388, birthdayGift: 88, holidayGift: 0, signInBonus: 10, deposit: 500000 },
        VIP5: { level: 5, salary: 588, upgradeBonus: 588, birthdayGift: 388, holidayGift: 0, signInBonus: 10, deposit: 1000000 },
        VIP6: { level: 6, salary: 888, upgradeBonus: 1288, birthdayGift: 588, holidayGift: 0, signInBonus: 20, deposit: 2000000 },
        VIP7: { level: 7, salary: 1288, upgradeBonus: 1888, birthdayGift: 1288, holidayGift: 0, signInBonus: 20, deposit: 5000000 },
        VIP8: { level: 8, salary: 1888, upgradeBonus: 2888, birthdayGift: 1888, holidayGift: 0, signInBonus: 30, deposit: 10000000 },
        VIP9: { level: 9, salary: 2888, upgradeBonus: 3888, birthdayGift: 2888, holidayGift: 0, signInBonus: 30, deposit: 20000000 },
        VIP10: { level: 10, salary: 3888, upgradeBonus: 5888, birthdayGift: 3888, holidayGift: 0, signInBonus: 50, deposit: 50000000 }
      },
      currVip: {},
      nextVip: {},
      userInfo:{},
    };
  },
  watch: {},
  computed: {
    ...mapGetters([ "getLoginInfo", "getLoginState"]),
    // nextLevel() {
    //   var active =  this.privileges[userInfo.VipLevelName].level
    //   return privileges[`VIP${active+1}`]
    // }
  },
  methods: {
    ...mapMutations(["SETALLBALANCES"]),
    ...mapActions(["saveLoginState"]),
    getAllBalances() {
      const self = this;
      getBalances().then((data) => {
        if (data.Code === "NoError") {
          self.SETALLBALANCES(data.Data);
          self.totalBalance = data.Data[1];
          self.paddingBalance = data.Data[2];
          self.walletBalance = data.Data[3];
        }
      });
    },
    openChangeLanguagePopup() {
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = true; //点击背景关闭
      self.$f7.dialog.open("#dialog-change-language", true);
    },
    logout() {
      const self = this;
      self.$f7.dialog
        .create({
          title: this.$t("common_0004"),
          text: this.$t("member_center_0045"),
          cssClass: "dialog-logout",
          buttons: [
            {
              text: this.$t("common_0002"),
              cssClass: "dialog-cancel",
              close: true,
            },
            {
              text: this.$t("common_0001"),
              cssClass: "dialog-ok",
              onClick: () => {
                logout().then((data) => {
                  common.logoutEvent(self);
                  self.saveLoginState();
                  self.$f7router.navigate("/");
                });
              },
            },
          ],
        })
        .open();
    },
    initBalanceInfo() {
      const self = this;
      self.showBalanceInfoPopup = true;
      checkBonusPointWallet().then((data) => {
        if (data.Code === "NoError") {
          self.currentBonusPoint = data.Data.CurrentBonusPoint;
        }
      });
      const param = { data: JSON.stringify({ Choose: false }) };
      userNameSignindetailDay(param).then((data) => {
        if (data.Code === "NoError") {
          self.userSignAmount = data.Data[1];
        }
      });
    },
    getUserInfoEvent() {
      const self = this;
      getUserInfo().then((data) => {
        if (data.Code === "NoError") {
          self.userInfo = data.Data;
          // console.log(this.userInfo.VipLevelName)
          var active =  self.privileges[self.userInfo.VipLevelName].level;
          self.currVip =  this.privileges[`VIP${active}`];
          if(active<10){
            self.nextVip =  this.privileges[`VIP${active+1}`];
          }
        }
      });
    },
    userMessgaeListEvent() {
      const self = this;
      const param = {
        data: JSON.stringify({
          Status: 2,
          BeginDate: null,
          EndDate: null,
          PageNow: 1,
          PageSize: "100",
        }),
      };
      getUserNewsList(param).then((data) => {
        if (data.Code === "NoError") {
          self.userMessgaeList = data.Data.Data;
        }
      });
    },
    tabInit(index) {
      setStore("mc-tab-active", index);
    },
    onPageInit() {
      this.saveLoginState();
      if (this.getLoginState) {
        // this.getUserInfo();
        this.getUserInfoEvent();
        this.getAllBalances();
        this.userMessgaeListEvent();
      }
    },
    onPageBeforeIn() {
      const self = this;
      self.$nextTick(() => {
        self.$f7.emit("myCustomEvent", "member-center");
        const tabActiveIndex = getStore("mc-tab-active") || 1;
        if (tabActiveIndex == 1) {
          self.$f7.tab.show("#tab-accountCenter");
        }
        if (tabActiveIndex == 2) {
          self.$f7.tab.show("#tab-selfHelpPrefer");
        }
        if (tabActiveIndex == 3) {
          self.$f7.tab.show("#tab-accountList");
        }
        if (tabActiveIndex == 4) {
          self.$f7.tab.show("#tab-personalInfo");
        }
        if (tabActiveIndex == 5) {
          self.$f7.tab.show("#tab-message");
        }
      });
    },
    onPageBeforeOut() {
      //removeStore('mc-tab-active');
    },
    closedBalanceInfo() {
      this.balancePopupOpened = false;
      this.showBalanceInfoPopup = false;
    },
  },
  created() {},
  mounted() {


  },
};
</script>
<style lang="less" scoped>
.panel-login-info {
  .welcome {
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
  }
  .row-totalAmt {
    text-align: center;
    line-height: 28px;
    padding: 10px;
    color: #ffffff;
    .totalBalance {
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
    }
    .totalAssets {
      font-size: 12px;
    }
    .loader {
      justify-content: center;
    }
  }
}
#tab-accountCenter{
  background: #f9fafb;
  // padding: 10px;

}

.vipsty{
  display: grid;
  grid-template-columns: 20% 80%;
  font-size: 12px;
  color: #b08537;
  letter-spacing: 1px;
  .tiao{
    height: 10px;
    width: 53%;
    border-radius: 5px;
    background: #d3b16a;
    position: absolute;
    right: 10px;
    bottom: 15px;
  }
  .jdnum{
    color: white;
    z-index: 10;
    position: absolute;
    font-size: 10px;
    right: 60px;
    bottom: 12px;
  }
}

.vipInfo{
  background: #987b51;
  display: grid;
  grid-template-columns: 50% 50%;
  color: white;
  padding: 10px 0;
  .left{
    display: grid;
    grid-template-columns: 40% 60%;
    div:nth-child(1){
      display: flex;
      align-items: center;
      justify-content: center;
    }
    div:nth-child(2){
      padding-top: 5px;
      p{
        margin: 2px 0;
      }
      p:nth-child(1){
        font-size: 12px;
      }
    }
  }
  .right{
    display: flex;
    line-height: 50px;
    .cnlogo{
      padding-top: 5px;
      margin-right: 10px;
      position: relative;
    }
    .cnlogo::after{
      content: '';
      width: 1px;
      height: 20px;
      position: absolute;
      background: #b09a7b;
      right: -7px;
      top: 14px;
    }
  }
}

.member-transfer {
  background: #987b51;
  padding: 8px 0;
  img {
    width: 28px;
  }
  a {
    text-align: center;
    p {
      font-size: 14px;
      color: #fff;
      margin: 0;
    }
  }
}
::v-deep .list .item-title{
  font-size: 14px;
}
::v-deep .ios .page-member-center .tabs-member-center .page-content .block .list ul{
  background-color: #f9fafb;
}
::v-deep .list ul{
  background-color: #f9fafb!important;
  li{
    background: #fff;
    margin-bottom: 10px;
    border-radius: 10px;
    padding-left: 13px;
  }
}
::v-deep .list .item-inner{
  justify-content: flex-start;
}
// ::v-deep .navbar-inner{    //头部颜色更换
//   color: white;
//   background: linear-gradient(to right, #987b51, #987b51);
// }
</style>
