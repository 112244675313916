<template>
  <f7-page no-toolbar no-swipeback name="direct-search" class="page-direct-search">
    <f7-navbar :title="$t('referrer_directReport_search_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-search">
      <div class="form">
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("referrer_directReport_search_0002") }}</div>
            <div class="item-input-wrap">
              <input type="text" readonly class="input-title" value="" v-model="dirctNumber" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("referrer_directReport_search_0003") }}</div>
            <div class="item-input-wrap">
              <input type="text" readonly class="input-title" value="" v-model="todayTotalPerformance" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("common_0029") }}</div>
            <div class="item-input-wrap">
              <input type="date" id="dateF" v-model="BeginDay" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("common_0030") }}</div>
            <div class="item-input-wrap">
              <input type="date" id="dateT" v-model="EndDay" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("referrer_directReport_records_0002") }}</div>
            <div class="item-input-wrap">
              <input type="text" class="input-title" value="" v-model="queryParam.UserNameDownline" />
            </div>
          </div>
        </div>
      </div>
    </f7-block>
    <div class="list inset button-search">
      <ul>
        <li>
          <a href="#" class="list-button" @click="searchSubmit">{{ $t("common_0028") }}</a>
        </li>
      </ul>
    </div>
  </f7-page>
</template>

<script>
import moment from "moment";
import { referrerdownlinememberlist } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      queryParam: {
        BeginDate: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        EndDate: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        UserNameDownline: "",
      },
      BeginDay: "",
      EndDay: "",
      dirctNumber: 0,
      todayTotalPerformance: 0,
    };
  },
  watch: {},
  computed: {},
  methods: {
    searchSubmit() {
      this.queryParam.BeginDate = this.BeginDay + " 00:00:00";
      this.queryParam.EndDate = this.EndDay + " 23:59:59";
      if (new Date(this.queryParam.BeginDate).getTime() > new Date(this.queryParam.EndDate).getTime()) {
        this.$f7.dialog.alert(this.$t("common_0031"), this.$t("common_0004"));
        return false;
      }
      this.$f7router.navigate(`/member-center/referrer-directReport-records/${JSON.stringify(this.queryParam)}/`);
    },
    getreferrerdownlinememberlist() {
      const self = this;
      var data = {
        UserNameDownline: "",
        BeginDate: new Date(this.queryParam.BeginDate).getTime() / 1000,
        EndDate: new Date(this.queryParam.EndDate).getTime() / 1000,
        PageNow: 1,
        PageSize: 10,
      };
      const params = { data: JSON.stringify(data) };
      referrerdownlinememberlist(params).then((data) => {
        if (data.Code === "NoError" && data.Data.length >= 3) {
          self.dirctNumber = data.Data[2];
          self.todayTotalPerformance = data.Data[3];
        }
      });
    },
    getUTCMinus4Date(diffDays) {
      let now = new Date();
      now.setDate(now.getDate() + diffDays);
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let day = now.getDate();
      return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
    },
  },
  created() {
    this.BeginDay = this.getUTCMinus4Date(0);
    this.EndDay = this.getUTCMinus4Date(0);
  },
  mounted() {
    this.getreferrerdownlinememberlist();
  },
};
</script>
<style lang="less" scoped>
.page-direct-search {
  .block-search {
    margin: 15px 10px;
    padding: 0;
    .list {
      margin: 0;
      ul {
        background-color: transparent;
        .item-input {
          .item-inner {
            .item-title {
              padding-top: 12px;
              width: 45%;
              font-size: 12px;
              color: #464646;
            }
            .item-input-wrap {
              font-size: 12px;
              color: #464646;
            }
            input {
              color: #fff;
              font-size: 12px;
              color: #464646;
            }
            input.input-title {
              font-size: 12px;
              color: #464646;
            }
          }
          .item-inner:after {
            right: 10px;
            left: auto;
          }
        }
        .item-title {
          color: #fff;
          font-size: 12px;
        }
      }
      ul:before,
      ul:after {
        height: 0;
      }
    }
  }
  .button-search {
    margin: 10px 12px;
    .list-button {
      color: #fff;
      background-color: var(--f7-base-red);
    }
    ul {
      background: var(--f7-theme-color);
    }
  }
}
</style>
