<template>
  <f7-page class="page-activity" name="activity" @page:beforein="onPageBeforeIn">
    <!-- <div class="header">
      <div class="navbar">{{ $t("activity_0001") }}</div>
      <van-tabs @change="activeTab" color="#4076fa" title-active-color="#4076fa">
        <van-tab :title="$t('common_0012')" :name="'tab-activity-other'">
          <div class="page-content">
            <template v-if="otherList.length > 0">
              <f7-card class="card-header-pic" v-for="item in otherList" :key="item.SpecialActivityID">
                <f7-card-header class="no-border" valign="bottom">
                  <img v-if="item.Type === 5" :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="apply(item.Id)" />
                  <img v-else :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="detail(item)" />
                </f7-card-header>
              </f7-card>
            </template>
            <template v-else-if="requestData">
              <f7-card class="no-data">
                <img
                  :src="`../../static/images/yt999/hd_nodata_${$f7.params.currLang}.png`"
                  width="45%"
                  :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`"
                  alt="No data"
                />
              </f7-card>
            </template>
          </div>
        </van-tab>
        <van-tab :title="$t('common_0005')" :name="'tab-activity-all'">
          <div class="page-content">
            <template v-if="activityList.length > 0">
              <f7-card class="card-header-pic" v-for="(item, index) in activityList" :key="index">
                <f7-card-header class="no-border" valign="bottom">
                  <img v-if="item.Type === 5" :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="apply(item.Id)" />
                  <img v-else :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="detail(item)" />
                </f7-card-header>
              </f7-card>
            </template>
            <template v-else-if="activityList.length <= 0 && requestData">
              <f7-card class="no-data">
                <img
                  :src="`../../static/images/yt999/hd_nodata_${$f7.params.currLang}.png`"
                  width="45%"
                  :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`"
                  alt="No data"
                />
              </f7-card>
            </template>
          </div>
        </van-tab>
        <van-tab :title="$t('common_0006')" :name="'tab-activity-egame'">
          <div class="page-content">
            <template v-if="egameList.length > 0">
              <f7-card class="card-header-pic" v-for="item in egameList" :key="item.SpecialActivityID">
                <f7-card-header class="no-border" valign="bottom">
                  <img v-if="item.Type === 5" :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="apply(item.Id)" />
                  <img v-else :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="detail(item)" />
                </f7-card-header>
              </f7-card>
            </template>
            <template v-else-if="requestData">
              <f7-card class="no-data">
                <img
                  :src="`../../static/images/yt999/hd_nodata_${$f7.params.currLang}.png`"
                  width="45%"
                  :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`"
                  alt="No data"
                />
              </f7-card>
            </template>
          </div>
        </van-tab>
        <van-tab :title="$t('common_0007')" :name="'tab-activity-casino'">
          <div class="page-content">
            <template v-if="casinoList.length > 0">
              <f7-card class="card-header-pic" v-for="item in casinoList" :key="item.SpecialActivityID">
                <f7-card-header class="no-border" valign="bottom">
                  <img v-if="item.Type === 5" :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="apply(item.Id)" />
                  <img v-else :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="detail(item)" />
                </f7-card-header>
              </f7-card>
            </template>
            <template v-else-if="requestData">
              <f7-card class="no-data">
                <img
                  :src="`../../static/images/yt999/hd_nodata_${$f7.params.currLang}.png`"
                  width="45%"
                  :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`"
                  alt="No data"
                />
              </f7-card>
            </template>
          </div>
        </van-tab>
        <van-tab :title="$t('common_0008')" :name="'tab-activity-card'">
          <div class="page-content">
            <template v-if="cardList.length > 0">
              <f7-card class="card-header-pic" v-for="item in cardList" :key="item.SpecialActivityID">
                <f7-card-header class="no-border" valign="bottom">
                  <img v-if="item.Type === 5" :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="apply(item.Id)" />
                  <img v-else :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="detail(item)" />
                </f7-card-header>
              </f7-card>
            </template>
            <template v-else-if="requestData">
              <f7-card class="no-data">
                <img
                  :src="`../../static/images/yt999/hd_nodata_${$f7.params.currLang}.png`"
                  width="45%"
                  :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`"
                  alt="No data"
                />
              </f7-card>
            </template>
          </div>
        </van-tab>
        <van-tab :title="$t('common_0009')" :name="'tab-activity-fish'">
          <div class="page-content">
            <template v-if="fishList.length > 0">
              <f7-card class="card-header-pic" v-for="item in fishList" :key="item.SpecialActivityID">
                <f7-card-header class="no-border" valign="bottom">
                  <img v-if="item.Type === 5" :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="apply(item.Id)" />
                  <img v-else :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="detail(item)" />
                </f7-card-header>
              </f7-card>
            </template>
            <template v-else-if="requestData">
              <f7-card class="no-data">
                <img
                  :src="`../../static/images/yt999/hd_nodata_${$f7.params.currLang}.png`"
                  width="45%"
                  :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`"
                  alt="No data"
                />
              </f7-card>
            </template>
          </div>
        </van-tab>
        <van-tab :title="$t('common_0010')" :name="'tab-activity-sports'">
          <div class="page-content">
            <template v-if="sportsList.length > 0">
              <f7-card class="card-header-pic" v-for="item in sportsList" :key="item.SpecialActivityID">
                <f7-card-header class="no-border" valign="bottom">
                  <img v-if="item.Type === 5" :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="apply(item.Id)" />
                  <img v-else :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="detail(item)" />
                </f7-card-header>
              </f7-card>
            </template>
            <template v-else-if="requestData">
              <f7-card class="no-data">
                <img
                  :src="`../../static/images/yt999/hd_nodata_${$f7.params.currLang}.png`"
                  width="45%"
                  :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`"
                  alt="No data"
                />
              </f7-card>
            </template>
          </div>
        </van-tab>
        <van-tab :title="$t('common_0011')" :name="'tab-activity-lottery'">
          <div class="page-content">
            <template v-if="lotteryList.length > 0">
              <f7-card class="card-header-pic" v-for="item in lotteryList" :key="item.SpecialActivityID">
                <f7-card-header class="no-border" valign="bottom">
                  <img v-if="item.Type === 5" :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="apply(item.Id)" />
                  <img v-else :data-src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in activity-lazy" @click="detail(item)" />
                </f7-card-header>
              </f7-card>
            </template>
            <template v-else-if="requestData">
              <f7-card class="no-data">
                <img
                  :src="`../../static/images/yt999/hd_nodata_${$f7.params.currLang}.png`"
                  width="45%"
                  :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`"
                  alt="No data"
                />
              </f7-card>
            </template>
          </div>
        </van-tab>
      </van-tabs>
    </div> -->
    <f7-navbar :title="$t('activity_0001')" :back-link="false" :no-hariline="true"></f7-navbar>
    <van-tabs class="promotion-groups" @click="activeTab" line-width="0">
      <van-tab v-for="(groups, key) of promotionCategory" :key="key" :title="groups.name" :name="'tab_' + key" :title-class="'title-' + key">
        <template #title>
          <img :src="`../static/images/yt999/home/nav-icon/nav_${key.toLowerCase()}.png`" alt="" />
          <span>{{ groups.name }}</span>
        </template>
        <template >
          <div v-for="(item,index) of groups.list" :key="index">
            <div class="item-promotion" >
              <div v-if="item" class="promotion-content">
                <div class="no-border" valign="bottom">
                  <img v-if="item.Type === 5" :src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in " @click="apply(item.Id)" />
                  <img v-else :src="`${baseImageUrl + item.PosterMobile}`" width="100%" class="lazy lazy-fade-in " @click="detail(item)" />
                </div>
              </div>
            </div>

          </div>
          <div v-if="groups.list.length==0&&nodata==1" class="no-data">
            <img :src="`../../static/images/yt999/hd_nodata_${$f7.params.currLang}.png`" width="45%" :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`" alt="No data" />
          </div>
        </template>

      </van-tab>
    </van-tabs>
    <f7-popup v-show="showActivityPopup" class="activity-detail-popup" :opened="popupOpened" @popup:open="openActivityPopup" @popup:close="closeActivityPopup">
      <f7-page>
        <f7-navbar :title="activityDetails.Name">
          <f7-nav-right>
            <f7-link popup-close>
              <i class="f7-icons">multiply_circle</i>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <div style="padding: 10px" v-html="activityDetails.ContentMobile" @click="selectedMsgClick"></div>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<script>
import { title } from "process";
import { getActivityList, getPromotionDetail } from "../../axios/api";
import { getStore, getCookie, removeStore } from "../../config/utils";
import { name } from "file-loader";
export default {
  components: {},
  props: {},
  data() {
    return {
      activityList: [],
      casinoList: [],
      lotteryList: [],
      sportsList: [],
      egameList: [],
      cardList: [],
      fishList: [],
      otherList: [],
      baseImageUrl: "",
      popupOpened: false,
      activityDetails: {},
      requestData: false,
      showActivityPopup: true,
      promotionCategory: {
        All: { name: "综合", list: [] },
        NewPlayer: { name: "迎新", list: [] },
        Egames: { name: "电子", list: [] },
        Casino: { name: "视讯", list: [] },
        Card: { name: "棋牌", list: [] },
        Fish: { name: "捕鱼", list: [] },
        SportsBook: { name: "体育", list: [] },
        Lottery: { name: "彩票", list: [] },
        None: { name: "其他", list: [] },
      },
      nodata:0
    };
  },
  watch: {},
  computed: {
    filterAllActivityList: function () {
      var list = this.activityList;
      return list.filter(function (item) {
        return item.Type == 5;
      });
    },
  },
  methods: {
    selectedMsgClick(event) {
      const target = event.target;
      if (target.tagName === "A") {
        event.preventDefault(); // 防止默认行为
        const url = target.getAttribute("href");
        window.open(url, "_blank");
      }
    },
    activeTab(name) {
      this.$f7.$(".tabs .page-content").removeClass("tab-active");
      this.$f7.$(`#${name}`).addClass("tab-active");
    },
    getIcon(gameType) {
      switch (gameType) {
        case "Casino":
          return "promtins-ico-casino.png";
        case "Egames":
          return "promtins-ico-egames.png";
        case "SportsBook":
          return "promtins-ico-sports.png";
        case "Fish":
          return "promtins-ico-fishing.png";
        case "Lottery":
          return "promtins-ico-lottery.png";
        case "Card":
          return "promtins-ico-cards.png";
        default:
          return "promtins-ico-other.png";
          break;
      }
    },
    onPageBeforeIn() {
      const self = this;
      removeStore("mc-tab-active");
      self.$f7.emit("myCustomEvent", "promotion");
    },
    requestActivity() {
      const self = this;
      const params = { data: JSON.stringify({ Type: 0 }) };
      getActivityList(params).then((data) => {
        if (data.Code === "NoError") {
          const d = data.Data;
          self.activityList = d;
          for (let item of d) {
            self.promotionCategory['All'].list.push(item);
            self.promotionCategory[item.GameType].list.push(item);
          }
          self.nodata = 1;

          // self.casinoList = d.filter((item) => {
          //   return item.GameType === `Casino`;
          // });
          // self.lotteryList = d.filter((item) => {
          //   return item.GameType === `Lottery`;
          // });
          // self.sportsList = d.filter((item) => {
          //   return item.GameType === `SportsBook`;
          // });
          // self.egameList = d.filter((item) => {
          //   return item.GameType === `Egames`;
          // });
          // self.cardList = d.filter((item) => {
          //   return item.GameType === `Card`;
          // });
          // self.fishList = d.filter((item) => {
          //   return item.GameType === `Fish`;
          // });
          // self.otherList = d.filter((item) => {
          //   return item.GameType === `None`;
          // });
        }
      });
    },
    detail(item) {
      this.activityDetails = item;
      this.popupOpened = true;
    },
    apply(id) {
      const self = this;
      if (!getCookie("zz")) {
        self.$f7router.navigate(`/login/`);
      } else {
        const params = { data: id };
        getPromotionDetail(params).then((data) => {
          if (data.Code === "NoError") {
            self.$f7router.navigate(`/activity/apply/${id}/`);
          } else {
            self.$f7.dialog.alert(data.Data ? data.Data : this.$t("activity_0003"), this.$t("common_0004"));
          }
        });
      }
    },
    openActivityPopup() {
      this.showActivityPopup = true;
    },
    closeActivityPopup() {
      this.popupOpened = false;
      this.showActivityPopup = false;
    },
  },
  created() {
    this.baseImageUrl = getStore("ImagesURL") + window.configs.promotionImg;
    this.requestActivity();
  },
  mounted() {
    this.activeTab("tab-activity-all");
  },
  updated() {
    this.$f7.lazy.create(this.$el);
  },
};
</script>
<style lang="less">
.activity-detail-popup {
  img {
    width: 100%;
  }
}
</style>
<style lang="less">
.page-activity {
  // background: url(../../static/images/yt999/promotion/bg-navbar.png) no-repeat;
  // background-size: 100% auto;
  background-color: #fff;

  .van-tabs {
    padding-bottom: 10px;

    .van-tabs__wrap {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 100%;
        background-color: #fff;
      }
    }

    .van-tabs__nav {
      background: transparent;
    }
  }

  .page-content {
    padding-top: calc(var(--f7-page-toolbar-top-offset, 0px) + var(--f7-page-searchbar-offset, 0px) + var(--f7-page-content-extra-padding-top, 0px));

    .navbar-inner {
      position: unset;
      // justify-content: center !important;
      text-align: center;
      background: #000;

      .title {
        margin: 0;
        background: red;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  .navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--f7-navbar-height);
    background: transparent;
    color: var(--f7-bars-text-color);
    color: var(--f7-navbar-text-color, var(--f7-bars-text-color));
    font-size: var(--f7-navbar-font-size);

    &::after {
      content: unset;
    }
  }
}

.buttons-tab {
  height: var(--f7-px-40);
  width: var(--f7-width-size-pt100);
  display: var(--f7-display-flex);
  justify-content: var(--f7-text-center);
  box-sizing: var(--f7-border-box);
  align-items: var(--f7-text-center);
  align-content: var(--f7-text-center);
  overflow: var(--f7-hidden);

  //border-bottom: var(--f7-padding-size-1) solid var(--f7-color-blue-menu);
  //border-top: var(--f7-padding-size-1) solid var(--f7-color-blue-menu);
  a.tab-link {
    width: var(--f7-width-size-pt100);
    height: var(--f7-height-size-pt100);
    box-sizing: var(--f7-border-box);
    display: var(--f7-display-flex);
    justify-content: var(--f7-text-center);
    align-items: var(--f7-text-center);
    flex-direction: var(--f7-flex-direction);
    text-transform: var(--f7-tabbar-link-text-transform);
    font-weight: var(--f7-tabbar-link-font-weight);
    letter-spacing: var(--f7-tabbar-link-letter-spacing);
    overflow: var(--f7-hidden);
    color: #808080;
    font-size: var(--f7-px-16);
  }

  a.tab-link.tab-link-active {
    color: #464646;
    border-bottom: 1px solid;

    i.iconfont {
      color: var(--f7-color-blue-active);
    }
  }
}

.tabs-promotion {
  position: relative;
  overflow: hidden;

  .tab {
    padding: var(--f7-padding-size-0);

    .card-all {
      background: #f3f3f5;
      margin: var(--f7-px-6);
      border: 1px solid #d2d2d2;
      padding: var(--f7-padding-size-4);

      .card-content {
        padding: 0;

        .row {
          width: 100%;
          align-items: center;

          .title-all {
            font-size: var(--f7-px-14);
            color: #464646;
            display: flex;
            align-items: center;
            flex: 1;

            .img {
              img {
                padding-top: 4px;
                padding-left: 4px;
                height: 32px;
              }
            }

            .t-name {
              padding-left: 10px;
              flex: 1;

              a {
                // line-height:40px;
                color: #464646;
              }

              a:active {
                color: #40baff;
              }
            }

            span {
              line-height: 40px;
              vertical-align: middle;
            }
          }

          .apply-all {
            width: auto;
            color: #196ec5;
            font-size: var(--f7-px-15);
            line-height: 40px;
            vertical-align: middle;

            .s2 {
              color: #ccc;
            }
          }

          .apply-all:hover {
            color: #196ec5;
          }
        }
      }
    }

    .card-header-pic {
      background: #f3f3f5;
      margin: var(--f7-px-6);
      border: 1px solid #d2d2d2;
      // padding: var(--f7-padding-size-4);
      margin-top: 5px;
      margin-bottom: 5px;

      .card-header {
        padding: var(--f7-padding-size-0);
        display: block;
        line-height: 0;

        img.activity-lazy {
          border-radius: 6px;
        }
      }

      .card-header:after {
        height: 0;
      }

      .card-content {
        padding: 0;

        .row {
          width: 100%;
          height: 30px;
          line-height: 30px;

          .title {
            font-size: var(--f7-px-14);
            color: #464646;
          }

          .title-all {
            font-size: var(--f7-px-14);
            color: #fff;
            height: 30px;

            .img {
              float: left;

              img {
                height: 30px;
              }
            }

            .t-name {
              padding-left: 38px;
              height: 30px;
              position: absolute;

              span {
                line-height: 30px;
              }
            }

            span {
              line-height: 40px;
              vertical-align: middle;
            }
          }

          .detail {
            color: #9e5d0d;
            font-size: var(--f7-px-15);

            .icon {
              color: #ccc;
            }
          }

          .detail:hover {
            color: #ccc;
          }

          .apply {
            color: #0480c5;
            font-size: var(--f7-px-15);

            .icon {
              color: #ccc;
            }
          }

          .apply:hover {
            color: #ccc;
          }

          .apply-all {
            color: #196ec5;
            font-size: var(--f7-px-15);
            line-height: 30px;
            vertical-align: middle;

            .s2 {
              color: #ccc;
            }
          }

          .apply-all:hover {
            color: #196ec5;
          }

          .iconfont {
            color: #007eff;
            margin-right: 4px;
            margin-top: -2px;
            font-size: 14px;
          }

          .iconfont-all {
            color: #007eff;
            margin-right: 4px;
            margin-top: -2px;
            font-size: 14px;
          }
        }
      }
    }

    .no-data {
      background-color: transparent;
      box-shadow: none;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      height: 50%;
    }
  }
}

.activity-detail-popup {
  .block {
    margin: var(--f7-margin-size-0);
    padding: var(--f7-padding-size-0);
    img {
      width: 100%;
    }
  }
}

.promotion-groups {
  margin-top: 45px;
  display: grid;
  grid-template-columns: calc(24% - 2%) calc(76% - 2%);
  column-gap: 2%;

  ::v-deep.van-tabs {
    .van-tabs__wrap {
      height: 100vh;
      &::after {
        content: none;
      }
      .van-tabs__nav {
        display: block;
        position: fixed;
        padding: 10px;
        width: 19%;
        .van-tab {
          height: 38px;
          margin-bottom: 10px;
          background: linear-gradient(180deg, #ffffff 0%, #e9f1ff 100%);
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          box-shadow: 0px 0px 4px 0px #d7d7d7;
          border-radius: 5px;
          color: #000000;
          .van-tab__text {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
            img {
              width: 20px;
              margin-right: 3px;
            }
          }
        }
        .van-tab--active {
          background: #9c865a;
          color: #ffffff;
          &.title-None {
            img {
              content: url(../../static/images/yt999/home/nav-icon/nav_none_act.png);
            }
          }
          &.title-SportsBook {
            img {
              content: url(../../static/images/yt999/home/nav-icon/nav_sportsbook_act.png);
            }
          }

          &.title-Casino {
            img {
              content: url(../../static/images/yt999/home/nav-icon/nav_casino_act.png);
            }
          }

          &.title-Egames {
            img {
              content: url(../../static/images/yt999/home/nav-icon/nav_egames_act.png);
            }
          }

          &.title-Lottery {
            img {
              content: url(../../static/images/yt999/home/nav-icon/nav_lottery_act.png);
            }
          }

          &.title-Card {
            img {
              content: url(../../static/images/yt999/home/nav-icon/nav_card_act.png);
            }
          }

          &.title-Fish {
            img {
              content: url(../../static/images/yt999/home/nav-icon/nav_fish_act.png);
            }
          }

          &.title-All {
            img {
              content: url(../../static/images/yt999/home/nav-icon/nav_all_act.png);
            }
          }
          &.title-NewPlayer {
            img {
              content: url(../../static/images/yt999/home/nav-icon/nav_newplayer_act.png);
            }
          }
        }
      }
    }
    .van-tabs__content {
      padding: 10px 0px;
      .item-promotion {
        .promotion-content {
          margin-bottom: 8px;
          img {
            border-radius: 10px;
            border: 1px solid #d7d7d7;
            box-shadow: 0px 0px 4px 0px #d7d7d7;
          }
        }
      }
      .no-data {
        background-color: transparent;
        box-shadow: none;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 50%;
      }
    }
  }
}
</style>
