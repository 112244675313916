<template>
  <f7-page no-toolbar no-swipeback name="member-info" class="page-member-info">
    <f7-navbar :title="$t('member_info_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-member-info" v-if="loadStatus">
      <div class="form">
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("member_info_0002") }}</div>
            <div class="item-input-wrap">
              <input type="text" readonly :value="userInfo.UserName.split('.')[1]" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("member_info_0003") }}</div>
            <div class="item-input-wrap">
              <input type="text" :readonly="userInfoStatus.SureName" :placeholder="$t('member_info_0004')" v-model="userInfoParam.SureName" />
            </div>
          </div>
        </div>
        <!-- <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t('member_info_0005') }}</div>
            <div class="item-input-wrap">
              <input
                    type="text"
                    readonly
                    :placeholder="$t('member_info_0006')"
                    :value="userInfoParam.Birthday|time('YYYY-MM-DD')"
                  >
            </div>
          </div>          
        </div> -->
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("member_info_0007") }}</div>
            <div class="item-input-wrap">
              <input v-if="userInfoStatus.Mobile == false" type="text" :placeholder="$t('member_info_0008')" v-model="userInfoParam.Mobile" />
              <input v-else type="text" :placeholder="$t('member_info_0008')" readonly v-model="formattedMobile" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("member_info_0011") }}</div>
            <div class="item-input-wrap">
              <input type="text" :placeholder="$t('member_info_0012')" :readonly="userInfoStatus.Email" v-model="userInfoParam.Email" />
              <!-- <span class="input-clear-button"></span> -->
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("member_info_0015") }}</div>
            <div class="item-input-wrap">
              <input type="text" readonly :value="userInfo.LastLoginTs | time_local" />
            </div>
          </div>
        </div>
        <!-- <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t('member_info_0016') }}</div>
            <div class="item-input-wrap">
              <input type="text" readonly :value="userInfo.LastLoginIP">
            </div>
          </div>          
        </div> -->
      </div>
    </f7-block>
    <div class="list inset button-save">
      <ul>
        <li>
          <a href="#" class="list-button color-black" @click="updateUserInfoEvent">{{ $t("common_0001") }}</a>
        </li>
      </ul>
    </div>
  </f7-page>
</template>
<script>
import { getUserInfo, updateUserInfo } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      userInfo: {},
      loadStatus: false,
      userInfoParam: {
        SureName: "",
        Mobile: "",
        Email: "",
        Wechat: "",
        Birthday: 0,
        QQ: "",
        Address: "",
      },
      userInfoStatus: {
        SureName: false,
        Mobile: false,
        Email: false,
        Wechat: false,
        QQ: false,
        Birthday: false,
        Address: false,
      },
    };
  },
  watch: {},
  computed: {
    formattedMobile: {
      get() {
        // 当读取数据时应用过滤器
        return this.formatPhoneNumber(this.userInfoParam.Mobile);
      },
      set(value) {
        // 当写入数据时更新原始数据
        this.userInfoParam.Mobile = value;
      },
    },
  },
  methods: {
    getUserInfoEvent() {
      const self = this;
      getUserInfo().then((data) => {
        if (data.Code === "NoError") {
          self.loadStatus = true;
          self.userInfo = data.Data;
          self.userInfoParam = {
            SureName: data.Data.SureName,
            Mobile: data.Data.Mobile,
            Email: data.Data.Email,
            Wechat: data.Data.Wechat,
            Birthday: data.Data.Birthday,
            QQ: data.Data.QQ,
            Address: data.Data.Address,
          };
          self.initUserStatus(data.Data);
        }
      });
    },
    formatPhoneNumber(value) {
      // 这里是您之前定义的过滤器逻辑
      if (!value) return "";
      value = value.toString();

      if (value.length >= 4) {
        const lastFourChars = value.substring(value.length - 4);
        return `******${lastFourChars}`;
      } else {
        return `******${value}`;
      }
    },
    initUserStatus(data) {
      if (data.SureName) {
        this.userInfoStatus.SureName = true;
      }
      if (data.Mobile) {
        this.userInfoStatus.Mobile = true;
      }
      if (data.Email) {
        this.userInfoStatus.Email = true;
      }
      if (data.Wechat) {
        this.userInfoStatus.Wechat = true;
      }
      if (data.QQ) {
        this.userInfoStatus.QQ = true;
      }
      if (data.Birthday && data.Birthday != "1900-01-01T00:00:00") {
        this.Birthday = true;
      }
      if (data.Address) {
        this.userInfoStatus.Address = true;
      }
    },
    updateUserInfoEvent() {
      const self = this;
      let sureName = /^[A-Za-zÀ-ÖØ-öø-ÿ' -]+$/;
      let iphoneNumber = /^\+?[0-9]{1,4}(?:\s?[0-9]{1,4})*$/;
      let QQ = /^[1-9][0-9]{5,10}$/;
      let wechat = /^[a-zA-Z0-9]{1}[-_a-zA-Z0-9]{5,19}$/;
      let Email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      let param = {
        SureName: "",
        Mobile: "",
        Email: "",
        Wechat: "",
        Birthday: 0,
        QQ: "",
        Address: "",
        CountryCodeID: 0,
      };

      if (!self.userInfoStatus.SureName) {
        // if ( !sureName.test(self.userInfoParam.SureName) && self.userInfoParam.SureName )
        // {
        //   self.$f7.dialog.alert(this.$t('member_info_0018'), this.$t('common_0004'));
        //   return false;
        // }
        param.SureName = self.userInfoParam.SureName;
      }

      if (!self.userInfoStatus.Mobile) {
        if (!iphoneNumber.test(self.userInfoParam.Mobile) && self.userInfoParam.Mobile) {
          self.$f7.dialog.alert(this.$t("member_info_0019"), this.$t("common_0004"));
          return false;
        }
        param.Mobile = self.userInfoParam.Mobile;
      }
      // if (!self.userInfoStatus.QQ) {
      //   if (!QQ.test(self.userInfoParam.QQ) && self.userInfoParam.QQ) {
      //     self.$f7.dialog.alert("this.$t('member_info_0020')", this.$t('common_0004'));
      //     return false;
      //   }
      //   param.QQ = self.userInfoParam.QQ;
      // }
      if (!self.userInfoStatus.Email) {
        if (!Email.test(self.userInfoParam.Email) && self.userInfoParam.Email) {
          self.$f7.dialog.alert(this.$t("member_info_0021"), this.$t("common_0004"));
          return false;
        }
        param.Email = self.userInfoParam.Email;
      }
      // if (!self.userInfoStatus.Address) {
      //   if (self.userInfoParam.Address == "") {
      //     self.$f7.dialog.alert(this.$t("member_info_0024"), this.$t("common_0004"));
      //     return false;
      //   }
      //   param.Address = self.userInfoParam.Address;
      // }

      // param.Birthday = self.userInsfoParam.Birthday;
      // if (!self.userInfoStatus.Wechat) {
      //   if (
      //     !wechat.test(self.userInfoParam.Wechat) &&
      //     self.userInfoParam.Wechat
      //   ) {
      //     self.$f7.dialog.alert("this.$t('member_info_0022')", this.$t('common_0004'));
      //     return false;
      //   }
      //   param.Wechat = self.userInfoParam.Wechat;
      // }
      const d = { data: JSON.stringify(param) };
      self.$f7.progressbar.show("blue");
      updateUserInfo(d).then((data) => {
        self.$f7.progressbar.hide();
        self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
        self.getUserInfoEvent();
      });
    },
  },
  created() {
    this.getUserInfoEvent();
  },
  mounted() {},
  updated() {},
};
</script>
<style lang="less" scoped>
.page-member-info {
  --f7-page-toolbar-bottom-offset: 0;
  .block-member-info {
    margin: 5px 0 10px;
    padding: 0 10px;
    .form {
      .form-item {
        .item-inner {
          .item-title {
            color: #464646;
            font-size: 14px;
          }
          .item-input-wrap {
            // width: 100%;
            background: #fafafa;
            border: 1px solid #1b2a3b10;
            padding: 10px;
            border-radius: 6px;
            margin: 2px 0 10px;
            input {
              width: 100%;
            }
          }
          .smart-select .item-after {
            max-width: unset;
          }
        }
      }
    }
  }
  .button-save {
    margin: 10px 12px;

    ul {
      background: #9C865A;
      li {
        a {
          color: #fff;
        }
      }
    }
  }
}
</style>
