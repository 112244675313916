<template>
  <f7-page no-toolbar no-swipeback name="winloserp" class="page-winloserp">
    <f7-navbar :title="$t('winloserp_0001')" back-link :no-hariline="true"></f7-navbar>
    <div class="top">
      <div class="statistics">
          <div class="title">{{ $t('winloserp_0002') }}&nbsp;({{dateRangeDesc}})</div>
          <div v-if="showWinlose" class="amount">{{totalStatistics| formatCurrency}}</div>
          <div v-else class="amount">--</div>
          <div class="desc">
            <p class="p1">{{ $t('winloserp_0003') }}</p>
            <p class="p2">{{ $t('winloserp_0004') }}&nbsp;+&nbsp;{{ $t('winloserp_0005') }}&nbsp;+&nbsp;{{ $t('winloserp_0006') }}&nbsp;+&nbsp;{{ $t('winloserp_0007') }}</p>
          </div>
      </div>
      <div class="info">
        <div class="">
          <span>{{ $t('winloserp_0008') }}：</span>
          <span v-if="showWinlose">{{winloseInfo[0].TotalEffectiveBetAmount| formatCurrency}}</span>
          <preloader :list="4" v-else style="float:left;margin:0;"></preloader>
        </div>
        <div class="">
          <span>{{ $t('winloserp_0004') }}：</span>
          <span v-if="showWinlose">{{winloseInfo[0].TotalWinlose| formatCurrency}}</span>
          <preloader :list="4" v-else style="float:left;margin:0;"></preloader>
        </div>
        <div>
          <span>{{ $t('winloserp_0009') }}：</span>
          <span v-if="showWinlose">{{totalDeposit| formatCurrency}}</span>
          <preloader :list="4" v-else style="float:left;margin:0;"></preloader>
        </div>
        <div class="">
          <span>{{ $t('winloserp_0010') }}：</span>
          <span v-if="showWinlose">{{totalWithdrawal| formatCurrency}}</span>
          <preloader :list="4" v-else style="float:left;margin:0;"></preloader>
        </div>
        <div>
          <span>{{ $t('winloserp_0005') }}：</span>
          <span v-if="showWinlose">{{totalBonus| formatCurrency}}</span>
          <preloader :list="4" v-else style="float:left;margin:0;"></preloader>
        </div>
        <div>
          <span>{{ $t('winloserp_0006') }}：</span>
          <span v-if="showWinlose">{{winloseInfo[1].Data.TotalCommission| formatCurrency}}</span>
          <preloader :list="4" v-else style="float:left;margin:0;"></preloader>
        </div>
        <div>
          <span>{{ $t('winloserp_0007') }}：</span>
          <span v-if="showWinlose">{{winloseInfo[1].Data.TotalRecommendBonus| formatCurrency}}</span>
          <preloader :list="4" v-else style="float:left;margin:0;"></preloader>
        </div>
      </div>
      <div class="line"></div>
      <div class="list inline-labels">
        <ul>
          <li>
            <a class="item-link smart-select smart-select-init" data-open-in="sheet">
              <select v-model="winloseParams.DateRangeType" name="select-funds">
                <option value=1>{{ $t('common_0034') }}</option>
                <option value=11>{{ $t('common_0035') }}</option>
                <option value=2>{{ $t('common_0036') }}</option>
                <option value=12>{{ $t('common_0037') }}</option>
                <option value=3>{{ $t('common_0038') }}</option>
                <option value=13>{{ $t('common_0039') }}</option>
              </select>
              <div class="item-content">
                <div class="item-inner">
                  <div class="item-title">{{ $t('common_0040') }}:</div>
                  <div class="item-after">{{ $t('common_0034') }}</div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="copy">
        <span class="copybutton"  @click="getMemberWinloseRP()">{{ $t('winloserp_0011') }}</span>
      </div>
    </div>
    </div>  
  </f7-page>
</template>

<script>

import {referrerinfo,commisionforecast,referreramounttomainwallet,getmemberwinloserp} from "../../../axios/api";
import preloader from "../../template/preloader";
import { getCookie } from "../../../config/utils";

export default {
  components: {preloader},
  props: {},
  data() {
    return {
      info: {
      },
      dateRangeDesc: this.$t('common_0034'),
      winloseParams:{
        // StartDate: 20210101,
        // EndDate: 20210712,
        DateRangeType: 1,
      },
      showWinlose:false,
      winloseInfo:{

      },
      totalStatistics: 0,
      totalDeposit: 0,
      totalWithdrawal: 0,
      totalBonus: 0,
      estimatedCommissionToday: 0,
      link: '',
    };
  },
  watch: {},
  computed: {
    
  },
  methods: {
    getMemberWinloseRP(){
      const self = this;
      self.showWinlose=false;
      const param = { data: JSON.stringify(self.winloseParams) };
      getmemberwinloserp(param).then((data)=>{
        if(data.Code === 'NoError'){
          setTimeout(()=>{
            self.winloseInfo = data.Data;
            console.log('totalDeposit=TotalOnlineAmount'+'('+self.winloseInfo[1].Data.TotalOnlineAmount+')+TotalOfflineAmount('+self.winloseInfo[1].Data.TotalOfflineAmount+')+TotalManualDepositAmount('+self.winloseInfo[1].Data.TotalManualDepositAmount+')');
            self.totalDeposit=self.winloseInfo[1].Data.TotalOnlineAmount+self.winloseInfo[1].Data.TotalOfflineAmount+self.winloseInfo[1].Data.TotalManualDepositAmount;

            console.log('totalWithdrawal=TotalWithdrawAmount'+'('+self.winloseInfo[1].Data.TotalWithdrawAmount+')+TotalManualWithdrawAmount('+self.winloseInfo[1].Data.TotalManualWithdrawAmount+')');
            self.totalWithdrawal=self.winloseInfo[1].Data.TotalWithdrawAmount+self.winloseInfo[1].Data.TotalManualWithdrawAmount;

            self.totalBonus=self.winloseInfo[1].Data.TotalRegisterBonus+self.winloseInfo[1].Data.TotalDepositBonus+self.winloseInfo[1].Data.TotalManualBonus-self.winloseInfo[1].Data.TotalManualDeductBonus;

            //投注盈亏+活动彩金+个人返水+推荐人返佣
            self.totalStatistics=self.winloseInfo[0].TotalWinlose+self.totalBonus+self.winloseInfo[1].Data.TotalCommission+self.winloseInfo[1].Data.TotalRecommendBonus;

            self.dateRangeDesc=self.getDateRangeDesc();
            self.showWinlose=true;
          },1000);
        }
      });
    },
    getDateRangeDesc(){
      const self=this;
      switch(self.winloseParams.DateRangeType.toString()){
        case "1":
          return this.$t('common_0034');
        case "2":
          return this.$t('common_0036');
        case "3":
          return this.$t('common_0038');

        case "11":
          return this.$t('common_0035');
        case "12":
          return this.$t('common_0037');
        case "13":
          return this.$t('common_0039');

        default:
          return "--";
      }
    },
  },
  created() {
    this.getMemberWinloseRP();
  },
  mounted() {

  }
};
</script>
<style lang="less" scoped>
.page-winloserp {
  color: #464646;
  .top{
    background: #fff;
    border: 1px solid #bfb6b6;
    border-radius: 4px;
    margin: 10px;
    font-size: 14px;
    .statistics{
      width: 90%;
      margin: auto;
      background: #f1f1f1;
      border-radius: 5px;
      text-align: center;
      margin-top: 10px;
      .title{
        text-align: center;
        font-size: 18px;
        padding-top: 6px;
        font-weight: bold;
        color: #ad5c09;
      }
      .amount{
        text-align: center;
        font-size: 18px;
        padding-top: 6px;
        font-weight: bold;
        color: #ad5c09;
      }
      .desc{
        border-top: 1px solid #e0dbdb;
        margin-top: 10px;
        .p1{
          margin: 10px;
          font-weight: bold;
        }
        .p2{
          margin-left: 10px;
          margin-right: 10px;
          padding-bottom: 10px;
          word-wrap:break-word;
        }
      }
    }
    .info{
      width: 90%;
      margin: auto;
      div{
        background: #f1f1f1;
        border-radius: 5px;
        height: 28px;
        line-height: 28px;
        margin-top: 6px;
        position: relative;
        span:nth-child(1){
          float: left;
          margin-left: 10px;
        }
        span:nth-child(2){
          color: var(--f7-theme-color);
        }
        .refer{
          background: linear-gradient(#decabc, #b19785);
          color: #000;
          height: 26px;
          border-radius: 4px;
          line-height: 28px;
          width: 50px;
          display: inline-block;
          text-align: center;
          position: absolute;
          right: 1px;
          top: 1px;
        }
      }
    }
    .line{
      width: 95%;
      margin: auto;
      margin-top: 15px;
      border-top: 1px solid #1b336e;
    }
    .list{
      margin: auto;
    }
    .share{
      width: 90%;
      background: #2d2d2d;
      color: #b1b9ce;
      border-radius: 15px;
      height: 30px;
      line-height: 30px;
      margin: 15px auto;
      position: relative;
      padding-left: 10px;
      .sharedesc{
        width: 96%;
        color: #9cfaff;
        white-space:nowrap;
        text-overflow: ellipsis; 
        -o-text-overflow: ellipsis;
      }
      .sharebutton{
        width: 80px;
        height: 28px;
        position: absolute;
        right: 1px;
        top: 1px;
        text-align: center;
        display: inline-block;
        color: #000;
        background: linear-gradient(#decabc, #b19785);
        border-radius: 15px;
      }
    }
    .copy{
      width: 90%;
      //background: #2d2d2d;
      color: #b1b9ce;
      border-radius: 15px;
      height: 30px;
      line-height: 30px;
      margin: 15px auto;
      position: relative;
      padding-left: 10px;
      .sharedesc{
        color: #9cfaff;
        text-overflow: ellipsis; 
        -o-text-overflow: ellipsis;
      }
      .copybutton{
        width: 100%;
        height: 36px;
        line-height: 36px;
        position: absolute;
        right: 1px;
        top: 1px;
        text-align: center;
        display: inline-block;
        color: #fff;
        background: var(--f7-base-red);
        border-radius: var(--f7-list-inset-border-radius);
        //border-radius: 15px;
        //border: #000 solid 1px;
      }
    }
  }
  .middle{
    border-top: 1px solid #bfbfbf;
    .wallet{
      background: url('../../../static/images/yt999/referrer/icon-up.png') bottom  no-repeat,url('../../../static/images/yt999/referrer/highlight.png') bottom #efefef no-repeat;
      background-size: 100%;
      padding-top: 15px;
      width: 50%;
      position: relative;
      >div{
        margin-bottom: 15px!important;
      }
      color: #101010;
      .rebtn{
        background: linear-gradient(#decabc, #b19785);
        border-radius: 4px;
        width: 82%;
        height: 30px;
        line-height: 30px;
        color: #000;
        margin: auto;
      }
      
    }
    .rightafter::after{
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      width: 2px;
      height: 128px;
      background: url('../../../static/images/yt999/navafter.png') bottom  no-repeat;
      background-size: 100% 100%;
    }
  }
  
  .bottom{
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    color: #101010;
    .s-title{
      font-size: 12px;
    }
  }
}
</style>