<template>
  <f7-page no-toolbar class="page-register" name="register">
    <f7-navbar :title="$t('register_0001')" back-link></f7-navbar>
    <form class="list no-hairlines-md" id="form-register">
      <div class="block-title">{{ $t("register_agent_0002") }}</div>
      <ul>
        <li class="item-content item-input" v-show="baseInfo.ShowPromotionCodeOnRegister">
          <div class="item-media">
            <i class="f7-icons">envelope_circle</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="text" name="promotion-code" :placeholder="$t('register_agent_0003')" v-model="promotionCode" />
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <!-- <li class="item-content item-input" >
          <div class="item-media">
            <i class="iconfont icon-iconfonttuijianren"></i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input
                type="text"
                name="promotion-code"
                :placeholder="$t('register_0002')"
                v-model="referrer"
              >
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li> -->
        <li class="item-content item-input">
          <div class="item-media">
            <i class="f7-icons">person_circle</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="text" name="user-name" :placeholder="$t('register_0003')" required />
              <!-- <span class="input-clear-button"></span> -->
              <f7-button v-show="baseInfo.EnableCheckUserExists" fill raised large class="detection" @click="checkUserExist()">{{ $t("register_0004") }}</f7-button>
            </div>
          </div>
        </li>
        <li class="item-content item-input">
          <div class="item-media">
            <i class="f7-icons">lock_shield</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="password" name="user-pwd" :placeholder="$t('register_agent_0005')" required />
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input">
          <div class="item-media">
            <i class="f7-icons">lock_shield</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="password" name="user-repwd" :placeholder="$t('register_agent_0006')" required />
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
      </ul>
      <div class="block-title">{{ $t("register_agent_0007") }}</div>
      <ul>
        <li class="item-content item-input item-input-with-info" v-show="CurrencyList.length > 1">
          <div class="item-media">
            <i class="f7-icons">creditcard</i>
          </div>
          <!-- <div class="item-inner"> -->
          <a class="item-link smart-select smart-select-init" data-open-in="sheet">
            <select v-model="CurrencyCode">
              <option v-for="item in CurrencyList" :key="item" :value="item">{{ item }}</option>
            </select>
            <!-- <div class="item-content"> -->
            <div class="item-inner">
              <div class="item-title">{{ $t("common_0021") }} - {{ CurrencyCode }}</div>
              <div class="item-after" style="display: none">{{ $t("common_0021") }}: {{ CurrencyCode }}</div>
            </div>
            <!-- </div> -->
          </a>
          <!-- </div> -->
        </li>

        <li class="item-content item-input item-input-with-info" v-if="baseInfo.NeedSureName || baseInfo.RegistMemberNeedBankCardInfo">
          <div class="item-media">
            <i class="f7-icons">person_circle</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="text" name="user-surename" :placeholder="$t('register_agent_0008')" />
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.RegistMemberNeedBankCardInfo">
          <div class="item-media">
            <i class="f7-icons">building_columns</i>
          </div>
          <a class="item-link smart-select smart-select-init" data-open-in="sheet">
            <select v-model="BankID" :placeholder="$t('register_agent_0013')">
              <option v-for="item in bankList" :key="item[0]" :value="item[1]">{{ item[1] }}</option>
            </select>
            <div class="item-inner">
              <div class="item-title" v-if="BankID !== ''">{{ BankID }}</div>
              <div class="item-title" v-else style="color: #acacac">{{ $t("register_agent_0013") }}</div>
              <div class="item-after" style="display: none"></div>
              <!-- <div class="item-after" v-else style="display:block;margin-left: inherit;">{{ $t('register_agent_0013') }}</div> -->
            </div>
          </a>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.RegistMemberNeedBankCardInfo">
          <div class="item-media">
            <i class="f7-icons">macwindow</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="number" name="bank-no" v-model="BankAccountNo" :placeholder="$t('bank_info_0010')" />
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>

        <li class="item-content item-input item-input-with-info" v-if="baseInfo.NeedPhone">
          <div class="item-media">
            <i class="f7-icons">globe</i>
          </div>
          <a class="item-link smart-select smart-select-init" data-open-in="sheet">
            <select v-model="CountryNameDesc">
              <option v-for="item in CountryCodeList" :key="item.NeedPhone" :value="item.NameDesc">{{ item.NameDesc }}</option>
            </select>
            <div class="item-inner">
              <div class="item-title">{{ CountryNameDesc }}</div>
              <div class="item-after" style="display: none"></div>
            </div>
          </a>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.NeedPhone">
          <div class="item-media">
            <i class="f7-icons">device_phone_portrait</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="number" name="user-phone" :placeholder="$t('register_agent_0009')" />
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.RegisterMobileVerify">
          <div class="item-media">
            <i class="f7-icons">lock_shield</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="text" name="mobile-verify" :placeholder="$t('register_0005')" required />
              <!-- <span class="input-clear-button"></span> -->
              <f7-button fill raised large class="detection detectionConde" @click="sendVerifyCode()" v-show="!countFlag">{{ $t("register_0006") }}</f7-button>
              <span class="detection detectionConde back" v-show="countFlag">{{ countDown }}&nbsp;s</span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.NeedEmail">
          <div class="item-media">
            <i class="f7-icons">envelope</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="email" name="user-email" :placeholder="$t('register_agent_0010')" />
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.NeedWechat">
          <div class="item-media">
            <i class="f7-icons">person_circle</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="text" name="user-wechat" :placeholder="$t('register_agent_0011')" />
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.NeedQQ">
          <div class="item-media">
            <i class="f7-icons">person_circle</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="number" name="user-qq" :placeholder="$t('register_agent_0012')" />
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.NeedSecurePassword">
          <div class="item-media">
            <i class="f7-icons">lock_shield</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="password" name="user-securitypwd" :placeholder="$t('register_agent_0016')" required />
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.NeedSecurePassword">
          <div class="item-media">
            <i class="f7-icons">lock_shield</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="password" name="user-reSecuritypwd" :placeholder="$t('register_agent_0017')" required />
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info">
          <div class="item-media">
            <i class="f7-icons">exclamationmark_shield</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="text" name="user-verify" :placeholder="$t('register_agent_0018')" required />
              <span class="span-verify">
                <img @click.self="updateVerify" :src="`${verifyUrl}`" alt />
              </span>
            </div>
          </div>
        </li>
      </ul>
      <f7-block strong class="regist-button-area">
        <f7-block-header class="text-center">
          <p>
            <f7-checkbox name="readRule" :checked="read" @change="checkRead"></f7-checkbox>
            <span class="text-remark">{{ $t("register_agent_0019") }}</span>
            &nbsp;
            <f7-link popup-open=".popup-general">《{{ $t("register_agent_0020") }}》</f7-link>
          </p>
        </f7-block-header>
        <f7-button fill raised large class="button-single" @click="registerNow">{{ $t("register_agent_0021") }}</f7-button>

        <f7-block-footer class="text-center">
          <span class="text-remark">{{ $t("register_0019") }}</span>
          <f7-link href="/login/" class="color-lightblue">{{ $t("register_0007") }}</f7-link>
        </f7-block-footer>
      </f7-block>
    </form>
    <f7-popup v-show="showRegisterPopup" class="popup-general" :opened="popupOpened" @popup:open="openRegisterPopup" @popup:close="closeRegisterPopup">
      <f7-page>
        <f7-navbar :title="$t('register_agent_0020')">
          <f7-nav-right>
            <f7-link popup-close>
              <i class="f7-icons">multiply_circle</i>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <ul>
            <li>
              {{ $t("register_agent_0022", [$f7.params.name]) }}
            </li>
            <li>{{ $t("register_agent_0023") }}</li>
            <li>
              {{ $t("register_0008", [$f7.params.name]) }}
            </li>
            <li>
              {{ $t("register_agent_0025", [$f7.params.name]) }}
            </li>
            <li>
              {{ $t("register_0009", [$f7.params.name]) }}
            </li>
            <li>
              {{ $t("register_0010", [$f7.params.name]) }}
            </li>
          </ul>
        </f7-block>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<script>
import api from "../../config/api";
import { getCookie, setCookie, getStore, setStore } from "../../config/utils";
import { mapGetters, mapMutations } from "vuex";
import { register, checkUserLogin, mobileVerifyCode, getCurrencyList, countryCodeListGet, getBlankList } from "../../axios/api";
import common from "../../service/common";
import http from "../../axios/http";
import { LANGLIST } from "@/locales/index";

export default {
  components: {},
  props: {},
  data() {
    return {
      read: true,
      uuid: getCookie("u"),
      verifyUrl: ``,
      baseInfo: {},
      userExist: false,
      checkRegisterInterval: null,
      popupOpened: false,
      promotionCode: "",
      referrer: "",
      countDown: 60,
      countFlag: false,
      CurrencyCode: "IDRK",
      CurrencyList: [],
      showRegisterPopup: true,
      CountryCodeID: -1,
      CountryNameDesc: "",
      CountryCodeList: [],
      bankList: [],
      BankID: "",
      BankName: "",
      BankAccountNo: "",
      BankAddress: "--",
    };
  },
  watch: {
    getMerchantInfo: function (val) {
      this.baseInfo = val.BaseInfo;
    },
  },
  computed: {
    ...mapGetters(["getMerchantInfo", "getLoginState"]),
  },
  methods: {
    ...mapMutations(["SETLOGINSTATE", "SETLOGININFO"]),
    checkRead() {
      this.read = this.read ? false : true;
    },
    getBankListEvent() {
      const self = this;
      getBlankList().then((data) => {
        if (data.Code === "NoError") {
          self.bankList = data.Data;
          // if (self.bankList.length > 0) {
          //   self.BankID = self.bankList[0][0];
          //   self.BankName = self.bankList[0][1];
          // }
        }
      });
    },
    updateVerify() {
      const uid = common.uuid();
      setCookie("u", uid, 7);
      this.verifyUrl = `${api.verifyimage}?u=${uid}`;
    },
    getCountryCodeList() {
      const self = this;
      countryCodeListGet().then((data) => {
        if (data.Code == "NoError") {
          this.CountryCodeList = data.Data;
          const item = this.CountryCodeList.find((item) => item.CountryID == self.baseInfo.DefaultCountryCodeID);
          console.log(self.baseInfo.DefaultCountryCodeID);
          if (item !== undefined) {
            this.CountryCodeID = item.CountryID;
            this.CountryNameDesc = item.NameDesc;
          } else {
            this.CountryCodeID = this.CountryCodeList[0].CountryID;
            this.CountryNameDesc = this.CountryCodeList[0].NameDesc;
          }
        }
      });
    },
    focusVerify() {
      const uid = common.uuid();
      setCookie("u", uid, 7);
      this.verifyUrl = `${api.verifyimage}?u=${uid}`;
    },
    async checkUserExist(event) {
      const self = this;
      const formData = self.$f7.form.convertToData("#form-register");
      const userReg = /^[a-zA-Z][0-9a-zA-Z]{3,14}$/;
      if (formData[`user-name`]) {
        if (!userReg.test(formData[`user-name`])) {
          self.$f7.dialog.alert(this.$t("register_agent_0029"), this.$t("common_0004"));
          return false;
        }
      } else {
        self.$f7.dialog.alert(this.$t("register_agent_0030"), this.$t("common_0004"));
        return false;
      }
      let res = await http.post(api.memberExist, { data: formData[`user-name`] });
      this.userExist = res.Data;
      if (res.Code === "NoError" && res.Data) {
        this.$f7.dialog.alert(this.$t("register_agent_0028"), this.$t("common_0004"));
      } else {
        this.$f7.dialog.alert(this.$t("register_0011"), this.$t("common_0004"));
      }
      return res.Data;
    },
    checkInput() {
      const self = this;
      const formData = self.$f7.form.convertToData(`#form-register`);
      const userReg = /^[a-zA-Z][0-9a-zA-Z]{3,14}$/,
        passReg = /^[\w\W]{6,18}$/,
        nameReg = /^[\u4E00-\u9FA5\uF900-\uFA2D|\.·]+$/,
        phoneReg = /^\d{6,15}$/,
        aqPassReg = /^\d{6}$/,
        wechatReg = /^[a-zA-Z0-9]{1}[-_a-zA-Z0-9]{5,19}$/,
        qqReg = /^[1-9][0-9]{4,12}$/,
        emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

      if (self.baseInfo.ShowPromotionCodeOnRegister && self.promotionCode == "") {
        self.$f7.dialog.alert(this.$t("register_0012"), this.$t("common_0004"));
        return false;
      }
      if (formData[`user-name`]) {
        if (!userReg.test(formData[`user-name`])) {
          self.$f7.dialog.alert(this.$t("register_agent_0029"), this.$t("common_0004"));
          return false;
        }
      } else {
        self.$f7.dialog.alert(this.$t("register_agent_0030"), this.$t("common_0004"));
        return false;
      }

      if (formData[`user-pwd`]) {
        if (!passReg.test(formData[`user-pwd`])) {
          self.$f7.dialog.alert(this.$t("register_agent_0031"), this.$t("common_0004"));
          return false;
        }
      } else {
        self.$f7.dialog.alert(this.$t("register_agent_0032"), this.$t("common_0004"));
        return false;
      }
      if (formData[`user-repwd`]) {
        if (formData[`user-repwd`] !== formData[`user-pwd`]) {
          self.$f7.dialog.alert(this.$t("register_agent_0033"), this.$t("common_0004"));
          return false;
        }
      } else {
        self.$f7.dialog.alert(this.$t("register_agent_0034"), this.$t("common_0004"));
        return false;
      }

      if (self.baseInfo.NeedSureName) {
        if (formData[`user-surename`]) {
          // if (!nameReg.test(formData[`user-surename`])) {
          //   self.$f7.dialog.alert(this.$t("register_agent_0035"), this.$t("common_0004"));
          //   return false;
          // }
        } else {
          self.$f7.dialog.alert(this.$t("register_agent_0036"), this.$t("common_0004"));
          return false;
        }
      }

      if (self.baseInfo.RegistMemberNeedBankCardInfo) {
        if (self.BankID == "") {
          self.$f7.dialog.alert(this.$t("register_agent_0013"), this.$t("common_0004"));
          return false;
        }
      }

      if (self.baseInfo.RegistMemberNeedBankCardInfo) {
        if (!formData[`bank-no`]) {
          self.$f7.dialog.alert(this.$t("bank_info_0011"), this.$t("common_0004"));
          return false;
        }
      }

      if (self.baseInfo.NeedPhone) {
        if (formData[`user-phone`]) {
          if (!phoneReg.test(formData[`user-phone`])) {
            self.$f7.dialog.alert(this.$t("register_agent_0037"), this.$t("common_0004"));
            return false;
          }
        } else {
          self.$f7.dialog.alert(this.$t("register_agent_0009"), this.$t("common_0004"));
          return false;
        }
      }

      if (self.baseInfo.RegisterMobileVerify) {
        if (!formData[`mobile-verify`]) {
          self.$f7.dialog.alert(this.$t("register_0013"), this.$t("common_0004"));
          return false;
        }
      }

      if (self.baseInfo.NeedEmail) {
        if (formData[`user-email`]) {
          if (!emailReg.test(formData[`user-email`])) {
            self.$f7.dialog.alert(this.$t("register_agent_0038"), this.$t("common_0004"));
            return false;
          }
        } else {
          self.$f7.dialog.alert(this.$t("register_agent_0039"), this.$t("common_0004"));
          return false;
        }
      }

      if (self.baseInfo.NeedWechat) {
        if (formData[`user-wechat`]) {
          if (!wechatReg.test(formData[`user-wechat`])) {
            self.$f7.dialog.alert(this.$t("register_agent_0040"), this.$t("common_0004"));
            return false;
          }
        } else {
          self.$f7.dialog.alert(this.$t("register_agent_0011"), this.$t("common_0004"));
          return false;
        }
      }

      if (self.baseInfo.NeedSecurePassword) {
        if (formData[`user-securitypwd`]) {
          if (!aqPassReg.test(formData[`user-securitypwd`])) {
            self.$f7.dialog.alert(this.$t("register_agent_0043"), this.$t("common_0004"));
            return false;
          }
        } else {
          self.$f7.dialog.alert(this.$t("register_agent_0044"), this.$t("common_0004"));
          return false;
        }
        if (!formData[`user-reSecuritypwd`] || formData[`user-reSecuritypwd`] !== formData[`user-securitypwd`]) {
          self.$f7.dialog.alert(this.$t("register_agent_0045"), this.$t("common_0004"));
          return false;
        }
      }

      if (!formData[`user-verify`]) {
        self.$f7.dialog.alert(this.$t("login_0006"), this.$t("common_0004"));
        return false;
      }
      if (!self.read) {
        self.$f7.dialog.alert(this.$t("register_0014"), this.$t("common_0004"));
        return false;
      }

      return true;
    },
    registerNow() {
      const self = this;
      const formData = self.$f7.form.convertToData("#form-register");
      let registerRequest = false;
      if (formData["user-phone"] && typeof formData["user-phone"] === "string") {
        formData[`user-phone`] = formData[`user-phone`].trim();
      }
      if (self.checkInput() && !registerRequest) {
        registerRequest = true;
        var bankCode = "";
        if (self.baseInfo.RegistMemberNeedBankCardInfo) {
          const activeBank = self.bankList.find((code) => code[1] == self.BankID);
          console.log(activeBank);
          self.BankID = activeBank[0];
          bankCode = self.BankID;
        }
        const activeCountryCode = self.CountryCodeList.find((code) => code.NameDesc == self.CountryNameDesc);
        self.CountryCodeID = activeCountryCode.CountryID;
        const param = {
          UserName: formData[`user-name`],
          Password: formData[`user-pwd`],
          SecurePassword: formData[`user-securitypwd`],
          SureName: formData[`user-surename`],
          Email: formData[`user-email`],
          QQ: formData[`user-qq`],
          Wechat: formData[`user-wechat`],
          VerifyCode: formData[`user-verify`],
          Mobile: formData[`user-phone`],
          PromoCode: self.promotionCode,
          Device: getStore("device") ? "App" : "Mobile",
          SmsVerifyCode: self.baseInfo.RegisterMobileVerify ? formData[`mobile-verify`] : "",
          Referrer: self.referrer,
          CheckPromoCode: self.baseInfo.ShowPromotionCodeOnRegister,
          CurrencyCode: self.CurrencyCode,
          CountryCodeID: self.CountryCodeID,
          BankCode: bankCode,
          BankAccountNo: self.BankAccountNo,
          BankAddress: self.BankAddress,
        };
        const d = { data: JSON.stringify(param) };
        self.$f7.progressbar.show(`blue`);
        register(d).then((data) => {
          registerRequest = false;
          self.$f7.progressbar.hide();
          if (data.Code === `NoError`) {
            // var PixelType = window.localStorage.getItem("PixelType");
            // var PixelID = window.localStorage.getItem("PixelID");
            // if (PixelID != "") {
            //   switch (PixelType) {
            //     case "Facebook_Pixel":
            //       if (typeof fbq !== "undefined") {
            //         fbq("init", PixelID);
            //         fbq("track", "CompleteRegistration");
            //       }
            //       break;
            //     default:
            //       break;
            //   }
            // }
            if (data.Data) {
              const userInfo = {
                la: data.Data.LastLoginAddress,
                li: data.Data.LastLoginIP,
                lt: data.Data.LastLoginTs,
              };
              setCookie(`zz`, data.Data.SessionId, 7);
              setStore("zz", data.Data.SessionId);
              setCookie(`LoginName`, data.Data.LoginName, 7);
              setStore(`ui`, JSON.stringify(userInfo));
              setStore(`lv`, data.Data.VipLevelName);
              setStore(`showNewPlayerNav`, true);
              self.SETLOGINSTATE(true);
              self.SETLOGININFO(data.Data);
              self.loginCheck();
              self.$f7router.navigate("/");
            } else {
              self.$f7.dialog.alert(this.$t("register_0015") + `<br/>` + this.$t("register_0016"), this.$t("common_0004"), () => {
                self.$f7router.navigate("/login/");
              });
            }
          } else {
            self.$f7.dialog.alert(data.Data, this.$t("common_0004"), () => {
              self.updateVerify();
            });
          }
        });
      }
    },
    sendVerifyCode() {
      const self = this;
      const formData = self.$f7.form.convertToData("#form-register");
      //验证手机号
      const phoneReg = /^\d{6,15}$/;
      if (self.baseInfo.NeedPhone) {
        if (formData[`user-phone`]) {
          if (!phoneReg.test(formData[`user-phone`])) {
            self.$f7.dialog.alert(this.$t("register_agent_0037"), this.$t("common_0004"));
            return false;
          }
        } else {
          self.$f7.dialog.alert(this.$t("register_agent_0009"), this.$t("common_0004"));
          return false;
        }
      }
      self.countFlag = true;
      self.countDown = 60;
      //验证码 start
      // 60倒计时
      const param = {
        Mobile: formData[`user-phone`],
        CountryCodeID: self.CountryCodeID,
      };
      const d = { data: JSON.stringify(param) };
      mobileVerifyCode(d).then((data) => {
        if (data.Code == "NoError") {
          // 发送成功
          self.$f7.dialog.alert(this.$t("register_0017"), this.$t("common_0004"));
        } else {
          self.$f7.dialog.alert(this.$t("register_0018"), this.$t("common_0004"));
        }
        //60秒倒计时
        var timer = setInterval(function () {
          self.countDown--;
          if (self.countDown === -1) {
            clearInterval(timer);
            self.countFlag = false;
          }
        }, 1000);
      });
    },
    checkUserLogin() {
      const self = this;
      if (self.getLoginState) {
        checkUserLogin().then((data) => {
          if (!data.Data || data.Code === "SessionNotExist") {
            clearInterval(self.checkRegisterInterval);
            self.SETLOGINSTATE(false);
            common.logoutEvent();
            self.$f7router.navigate(`/`);
          }
        });
      }
    },
    loginCheck() {
      const self = this;
      clearInterval(self.checkRegisterInterval);
      self.checkUserLogin();
      self.checkRegisterInterval = setInterval(() => {
        self.checkUserLogin();
      }, 15000);
    },
    getSupportCurrencyList() {
      getCurrencyList().then((data) => {
        if (data.Code === `NoError`) {
          const self = this;
          self.CurrencyList = data.Data;
          const lang = LANGLIST[this.$f7.params.currLang];
          if (lang) {
            self.CurrencyCode = self.CurrencyList.find((code) => code == lang.currency);
          }
          if (!self.CurrencyCode) {
            self.CurrencyCode = self.CurrencyList.find((code) => code == "USD");
          }
          if (!self.CurrencyCode && self.CurrencyList.length) {
            self.CurrencyCode = self.CurrencyList[0];
          }
        }
      });
    },
    openRegisterPopup() {
      this.showRegisterPopup = true;
    },
    closeRegisterPopup() {
      this.popupOpened = false;
      this.showRegisterPopup = false;
    },
  },
  created() {
    const self = this;
    this.getBankListEvent();
    const d = self.$f7route.query.d || getStore("d");
    const r = self.$f7route.query.r || getStore("r");
    if (d || r) {
      //说明是从推广链接来的 必有其中一个参数
      if (d) {
        setStore("d", d);
        if (d != "") {
          self.promotionCode = d;
        }
      } else {
        setStore("d", "");
      }
      if (r) {
        setStore("r", r);
        if (r != "") {
          self.referrer = r;
        }
      } else {
        setStore("r", "");
      }
    }
    if (self.referrer == "") {
      self.referrer = getStore("r");
    }
    if (self.promotionCode == "") {
      self.promotionCode = getStore("d");
    }
    self.verifyUrl = `${api.verifyimage}?u=${getCookie(`u`)}`;
    self.baseInfo = self.getMerchantInfo.BaseInfo ? self.getMerchantInfo.BaseInfo : ``;
    setTimeout(() => {
      console.log(self.getMerchantInfo.PromotionCode);
      if (self.getMerchantInfo.PromotionCode != undefined && self.getMerchantInfo.PromotionCode != "") {
        self.promotionCode = self.getMerchantInfo.PromotionCode;
      }
    }, 3000);
    self.getSupportCurrencyList();
    self.getCountryCodeList();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.page-register {
  .list {
    margin: var(--f7-margin-size-10) 0;
    .item-media {
      .f7-icons {
        margin-top: 7px;
      }
    }
    .block-title {
      background: url(../../static/images/yt999/register_title.png) no-repeat;
      width: 225px;
      height: var(--f7-height-size-30);
      line-height: var(--f7-height-size-30);
      background-size: cover;
      color: #fff;
      margin-left: var(--f7-size-ps-12);
      margin-bottom: var(--f7-margin-size-0);
      margin-top: var(--f7-margin-size-15);
      padding-left: var(--f7-px-5);
      font-size: var(--f7-font-size-14);
    }
    ul {
      background-color: var(--f7-color-bg-transparent);
      .item-content {
        margin-bottom: var(--f7-margin-size-10);
        padding-left: var(--f7-px-20);

        input {
          font-size: var(--f7-font-size-14);
          color: #464646;
        }

        .input-clear-button {
          right: var(--f7-size-ps-12);
        }

        .iconfont {
          color: #464646;
        }
        .span-verify {
          position: var(--f7-postion-absolute);
          right: var(--f7-px-10);
          top: var(--f7-px-4);
          z-index: 2;
        }
      }
    }
    ul:before,
    ul:after {
      height: 0;
    }
  }
  .list .item-inner:after {
    width: var(--f7-width-size-pt92);
  }

  .list .item-link {
    color: inherit;
    flex: 1;
    align-items: center;
    margin-left: var(--f7-list-item-media-margin);

    .item-inner {
      justify-content: center;
      font-size: var(--f7-font-size-14);
      color: #464646;

      .item-after {
        display: none;
      }
    }

    .item-inner::before {
      right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right) + 8px);
    }
  }

  .list li:last-child > .item-inner:after {
    display: var(--f7-display-block) !important;
  }

  .block-strong {
    padding: var(--f7-padding-size-10) var(--f7-padding-size-20, --f7-block-padding-horizontal);
    margin: var(--f7-margin-size-0) auto;
    .block-header {
      margin-bottom: var(--f7-margin-size-15);
    }
    .block-footer {
      margin-top: var(--f7-margin-size-15);
      a.link {
        border-bottom: 1px solid var(--f7-color-lightblue);
      }
    }
  }
  .block-strong:before,
  .block-strong:after {
    height: var(--f7-height-size-0);
  }
}
.popup-general {
  ul {
    padding: var(--f7-padding-size-0);
    li {
      list-style: var(--f7-border-none);
      padding-bottom: var(--f7-padding-size-10);
      margin-bottom: var(--f7-margin-size-10);
      border-bottom: 1px dashed #666;
      font-size: var(--f7-px-16);
      color: var(--f7-color-gray-1);
    }
    li:last-child {
      padding-bottom: 0px;
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}
.item-input-wrap {
  position: relative;
  .detection {
    display: block;
    position: absolute;
    top: 9px;
    right: 10px;
    font-size: 14px;
    width: 78px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 5px;
  }
  .detectionConde {
    width: 92px;
  }
  .back {
    background: #298fff;
    color: #fff;
  }
}
.list .item-inner:after {
  height: 2px;
}
</style>
