const api = {
  // 登录与注册模块
  register: "/d/member/register", //注册页面
  mobileVerify: "/d/member/verify", //短信验证码
  getBrand: "/d/m/merchant/getinfo", //品牌设定
  login: "/sess_api/member/login", //会员登录
  agentRegister: "/d/agent/register", //代理注册
  verifyimage: "/sess_api/m/verifyimage", //验证码
  memberExist: "/d/m/member/exist", //用户账号检测
  agentExist: "/d/m/agent/exist", //代理商注册账号检测
  getLogo: "/d/m/logo/get", //獲取Logo
  getBasesetList: "/d/m/baseset/list", //获取联系方式
  hotGameLists: "/app_api/game/hotlist", //热门游戏
  getMemberBalance: "/d/member/balance", //仅仅获取钱包余额

  getAgloginentry: "/d/member/agloginentry", //获取代理登录入口

  getTestUser: "/d/m/member/createtest", //获取免费试玩账号

  getGameListp: "/rp_api/member/game/parentlist", //获取游戏分类
  getGameClassListc: "/rp_api/member/game/childlist", //获取游戏分类的子分类
  getFisheListbyClass: "/rp_api/member/game/fishlist", //获取捕鱼游戏列表
  getGameListbyClass: "/rp_api/member/game/gamelist", //获取游戏列表
  getGamebyhotlist: "/rp_api/member/game/gamebyhotlist", //热门游戏列表

  getActivityList: "/rp_api/member/promotionlist", //获取活动列表
  getPromotionlist: "/act_api/sactivity/getlist", //获取优惠活动列表
  getBonus: "/act_api/sactivity/getbonus", //领取优惠活动奖励

  getSystemPopups: "/rp_api/message/membernoticelist", //获取系统弹窗消息
  getUserPopups: "/rp_api/message/u/poplist", //获取用户弹窗消息

  getgameentry: "/d/m/member/getgameentry", //获取游戏地址
  getgameentryauto: "d/m/member/getgameentryauto", //获取游戏地址同时将钱转到该游戏
  checkPermissions: "/d/access/check", //检测用户权限
  checktransfer: "/d/m/member/checktransfer", //查看用户进入游戏钱是否足够
  getCarouseladvertList: "/d/m/carouseladvert/list", //轮播图
  getLadvertList: "/rp_api/member/carouseladvertsettingmemberlist", //弹框广告图

  // getGameListbyClass:"/d/game/listbyclass", //获取游戏列表
  /*会员中心*/

  checkUser: "/sess_api/s/check", //每隔30秒检查一次
  getAmericanTime: "/d/getsystime", //获取美东时间
  loginOut: "/sess_api/s/logout", //注销
  checkSecurePwdStatus: "/d/m/member/checksecurepwdstatus", //检查是否绑定安全密码

  // 额度转换模块
  getallvalidprovider: "/d/m/member/getallvalidprovider", //获取各个游戏列表
  getapibalances: "/d/m/member/getapibalances", //获取钱包余额
  recyclingFunds: "/d/m/member/withdrawall", //资金回收
  toGame: "/d/m/member/deposit", //钱包存游戏
  toBalance: "/d/m/member/withdraw", //游戏存钱包
  getApibalance: "/d/m/member/getapibalance", //获取单个钱包
  oneKeyIn: "/d/m/member/depositothertoapi", //一键转入
  oneKeyOut: "/d/m/member/withdrawapi", //一键转出
  validproviders: "/d/m/merchant/validproviders",
  mainwalletdetail: "/d/m/member/mainwalletdetail",

  //存款专区
  onlineBankSumbit: "/op_api/m/pay/netbank/pay",
  //银行转账
  getBlankList: "/d/bank/listname", //获取银行列表
  getPayBankList: "/d/m/pay/bank/list", //获取线下收款账号
  payBankPay: "/d/m/pay/bank/pay", //线下银行转账支付
  //在线充值
  getPayAccList: "/d/m/pay/acc/list", //获取在线充值方式
  onlinePay: "/op_api/m/pay/online/pay", //在线充值
  //线下充值
  getQR: "/d/m/pay/qr/list", //获取支付二维码
  payQR: "/d/m/pay/qr/pay", //线下支付接口

  //取款专区
  getvalidlist: "/d/m/promotion/validlist", //获取优惠列表
  checkwr: "/d/m/member/checkwr", //查询当前正在申请的提现请求数
  getdrinfo: "/d/m/member/getdrinfo", //获取用户取款信息
  checksp: "/d/m/member/checksp", //安全密码校验
  memberWr: "/d/m/member/wr", //申请取款
  getTurnoverauditlist: "/rp_api/member/turnoverauditlist", //取款稽核详情

  //资金记录
  // getFundsdetails:"/d/m/member/fundsdetails",  //获取资金记录
  getFundsdetails: "/rp_api/member/usernamefundsdetailsget", //获取优惠记录
  activitybonusauditusernameGet: "/rp_api/member/activitybonusauditusernameGet", //优惠审核

  //获取投注记录
  getProvidergame: "/d/game/providergame", //获取游戏商和游戏种类
  getTransactionlist: "/rp_api/member/gamestransaction/gametransactionlist", //获取投注记录
  getBonuspointdetaillist: "/rp_api/member/bonuspointdetaillist", //获取积分记录
  usernamewithdrawrecordget: "/rp_api/member/usernamewithdrawrecordget", //获取提款记录
  usernameonlineandofflinepaymentget: "/rp_api/member/usernameonlineandofflinepaymentget", //获取存款记录
  //消息公告
  getSystemList: "/rp_api/message/membernoticelist", //获取系统公告
  getLamplist: "/rp_api/message/lamplist", //获取滚动的公告
  getMsgSystemList: "/d/merchantmessage/list", //获取消息中心公告
  getUserNewsList: "/rp_api/m/agent/usernamemessageget", //获取用户系统消息
  alreadyRead: "/d/message/u/setread", //更新消息为已阅读
  getnotreadcount: " /rp_api/member/usernamemessagenotreadecountget", //获取未读消息数量

  //个人资料
  getInfo: "/d/m/member/getinfo", //获取个人信息
  bankbind: "/d/m/member/bankbind", //绑定银行卡
  updatepersonal: "/d/m/member/updatepersonal", //会员信息修改
  updatesecurepwd: "/d/m/member/updatesecurepwd", //修改会员安全密码
  updateloginpwd: "/d/m/member/updateloginpwd", //修改会员登录密码

  /*签到*/
  postSingIn: "/act_api/signin/do", //签到
  postCheckwallet: "/act_api/signin/checkwallet", //获取签到钱包信息
  getUsernameSignindetailDay: "/rp_api/member/usernamesignindetaildayget", //签到天数
  getVipsigninsettinglist: "/rp_api/member/vipsigninsettinglist", //签到列表

  postForPoints: "/act_api/bonuspoint/checkwallet", //获取积分钱包信息
  getbonuspointgoodslist: "/rp_api/member/bonuspointgoodslist", //积分详情
  doBonuspointUse: "/act_api/bonuspoint/use", //积分兑换

  getBirthdayStatus: "/act_api/vip/birthday/check", //查询生日礼金状态
  getBirthdayBonus: "/act_api/vip/birthday/getbonus", //领取生日礼金

  checkMonthlySalary: "/act_api/vip/monthlysalary/check", //查询每月薪水
  getMonthlySalary: "/act_api/vip/monthlysalary/getbonus", //领取每月薪水

  checkFestival: "/act_api/vip/festival/check", //检查节日礼金
  getFestivalBonus: "/act_api/vip/festival/getbonus", //领取节日礼金

  checkLevelup: "/act_api/vip/levelup/check", //查询晋级状态
  doLevelup: "/act_api/vip/levelup/do", //自主晋级
  getmissionlist: "/act_api/firstpagemissionget", //获取首页活动
  receiveBouns: "/act_api/missionbonusget", //领取奖励

  getSecurityQuestionList: "/rp_api/member/securityquestionlist", //获取密保问题
  getSecurityQuestionStatus: "/rp_api/member/securityanswerexistscheck", //获取密保设置状态
  setSecurityQuestionAnswer: "/d/m/membersa/init", //设置密保
  updateSecurityQuestionAnswer: "/d/m/membersa/update", //修改密保
  getPromotionDetail: "/promotion_api/member/getdetail", //查询优惠活动详情
  postPromitionDetail: "/promotion_api/member/submit", //提交申请优惠活动申请
  getActivityAuditUserName: "/rp_api/member/promotionverifyusernameGet", //活动审核

  //保险箱
  checksecuritypass: "/d/member/checksecuritypass", //检测安全密码是否正确
  safetyboxbalance: "/d/member/safetyboxbalance", //获取保险箱余额
  safetyboxtransfer: "/d/member/safetyboxtransfer", //保险箱余额转入转出
  safetyboxtransferhistorymemeberlist: "/rp_api/member/safetyboxtransferhistorymemeberlist", //保险箱转账记录

  //我的推广页
  referrerinfo: "/d/member/referrerinfo", //查询会员自身的推广信息
  commisionforecast: "/d/member/commisionforecast", //今日预估佣金
  referreramounttomainwallet: "/d/member/referreramounttomainwallet", ///领取佣金
  referrercommision: "/d/member/getreferrercommision", // 推广-返佣金额对照表
  referrerdownlinememberlist: "/rp_api/member/referrerdownlinememberlist", //直属查询
  referrertransferhistorylist: "/rp_api/member/referrertransferhistorylist", //领取记录
  referrerDownlineMemberEnterpriseList: "/rp_api/member/referrerdownlinememberenterpriselist", //业绩查询
  referrerdownlinememberenterprisedetaillist: "/rp_api/member/referrerdownlinememberenterprisedetaillist", //业绩查询详情

  getCurrencyList: "/d/member/currencylist", //获取支持的货币列表
  countrycodelistget: "/rp_api/member/country_list", //获取国家列表
  referralplandetail: "/d/member/referralplandetail",
  memberwinloserp: "/rp_api/member/memberdailywinloserp", //会员盈亏统计
  depositqalist: "/rp_api/member/membermerchantdepositqalist", //获取问答列表
  onlinecslist: "/rp_api/member/onlinecslist", //获取在线客服列表
  offlinePaymentOrderCheck:"/rp_api/member/offline_payment_order_check",
};
export default api;
