<template>
  <!-- Views/Tabs container -->

  <f7-page @page:beforein="onPageBeforeIn" no-swipeback name="online-cs" class="online-cs-info">
    <!-- 在线客服popup -->
    <f7-popup v-show="showOnlineCustomPopup" class="onlineCustom-popup" :opened="onlinePopupOpened" @popup:open="openOnlineCustomPopup" @popup:close="closeOnlineCustomPopup">
      <f7-page>
        <f7-navbar :title="$t('login_0012')">
          <f7-nav-right>
            <f7-link popup-close><i class="f7-icons">multiply_circle</i></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <iframe :src="onlineCustomUrl" class="iframe-onlineCustom" frameborder="0"></iframe>
      </f7-page>
    </f7-popup>
    <f7-navbar :no-hariline="true" innerClass="inner-bankInfo">
      <!-- <f7-nav-left>
            <f7-link @click="back">
              <i class="f7-icons">chevron_left</i>
            </f7-link>
          </f7-nav-left> -->
      <f7-nav-title>{{ $t("login_0012") }}</f7-nav-title>
    </f7-navbar>
    <template>
      <div class="qaimg">
        <ul>
          <li>
            <img class="button-single" src="../../../static/images/yt999/qa.png" />
          </li>
        </ul>
        <!-- <div class="curr-time">{{ utcMinusFourTime }}</div> -->
        <div class="list inset button-save">
          <ul>
            <li>
              <a href="#" class="list-button button-single" @click="openServiceAction()" click="QASubmit">
                <span class="material-icons" style="color: white; vertical-align: sub">headset_mic</span>
                <span style="display: inline-block">{{ $t("login_0012") }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <f7-tab id="tab-accountCenter" class="page-content-cs" tab-active>
        <f7-block>
          <f7-list>
            <f7-list-item v-for="item in onlinecsList" :key="item.CSID" src="" @click="goCSPage(item.Link)" class="icon-animation">
              <span class="title">{{ item.Title }}</span>
              <img class="img-ico" :src="'../../../static/images/yt999/cs_ico/' + item.CSType + '.png'" />
              <i class="f7-icons go-ico">chevron_right</i>
            </f7-list-item>
          </f7-list>
        </f7-block>
      </f7-tab>
      <div class="block accordion-list">
        <div class="accordion-item" v-for="item in depositqaList" :key="item.QID">
          <div class="accordion-item-toggle">
            <div class="row no-gap">
              <div class="col-90 text-left col-title" style="padding: 10px; width: 100%">
                <img class="help" src="../../../static/images/yt999/help.png" />
                <div class="s-title">{{ item.CnName_Title }}</div>
              </div>
            </div>
          </div>
          <div class="accordion-item-content">
            <div block class="ans" style="margin: 5px 10px 20px 5px; word-break: break-all; word-wrap: break-word; width: 84%; margin: auto; margin-bottom: 20px">{{ item.CnName_Answer }}</div>
          </div>
        </div>
      </div>
    </template>
  </f7-page>
</template>
<script>
import { depositqalist, getInfo, getonlinecslist } from "../../../axios/api";
import { getCookie, setStore, getStore, setCookie } from "../../../config/utils";
import { mapGetters } from "vuex";
import moment from "moment";
let self;
export default {
  components: {},
  props: {},
  data() {
    return {
      intervalId: null,
      utcMinusFourTime: "",
      depositqaList: [],
      onlinecsList: [],
      memberBankCardList: [],
      onlinePopupOpened: false,
      onlineCustomUrl: "",
      SureNameFlag: false,
      baseInfo: {},
      showOnlineCustomPopup: true,
      isAppDevice: getStore("device"),
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["getMerchantInfo", "onlineCustomLoaded"]),
  },
  methods: {
    updateTime() {
      const date = new Date();
      const offset = -4 * 60;
      const utcMinusFourDate = new Date(date.getTime() + offset * 60000);
      const formattedTime = this.formatDate(utcMinusFourDate);

      this.utcMinusFourTime = formattedTime + " (UTC-4)";
    },
    formatDate(date) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZone: "UTC",
      };
      return date.toLocaleString("en-US", options).replace(",", "");
    },
    onError(e) {
      console.log(e);
    },
    goCSPage(url) {
      if (url == "") {
        return;
      }
      if(this.isAppDevice){
        window.location.href = url;
      }else{
        window.open(url, "_blank");
      }
    },
    getMerchantInfoDetail() {
      const self = this;
      getInfo().then((data) => {
        if (data.Code === "NoError" && data.Data) {
          self.baseInfo = data.Data.BaseInfo;
        }
      });
    },
    getDepositQAListEvent() {
      const self = this;
      depositqalist().then((data) => {
        if (data.Code === "NoError") {
          self.depositqaList = data.Data;
        }
      });
    },
    getCSList() {
      const self = this;
      getonlinecslist().then((data) => {
        if (data.Code === "NoError") {
          self.onlinecsList = data.Data;
        }
      });
    },
    openServiceAction() {
      const self = this;
      const baseInfo = self.baseInfo;
      if (baseInfo.CSType == 2) {
        if (!this.onlineCustomLoaded) {
          let loading = self.$f7.dialog
            .create({
              title: "",
              text: "Loading...",
              cssClass: "dialog-preloadgame",
            })
            .open();
          setTimeout(() => {
            loading.close();
            this.openServiceAction();
          }, 1500);
        } else {
          window.Tawk_API.toggle();
        }
      } else {
        self.onlineCustomUrl = baseInfo.MobileService;
        self.onlinePopupOpened = true;
      }
    },
    onlineCustomService() {
      const self = this;
      self.$f7.actions.close(".actions-qq");
      self.$f7.actions.close(".actions-wechat");
      self.onlinePopupOpened = true;
    },
    back() {
      this.$f7router.back();
    },
    onPageBeforeIn() {
      const self = this;
      self.$nextTick(() => {
        self.$f7.emit("myCustomEvent", "online-cs");
      });
    },
    openOnlineCustomPopup() {
      this.showOnlineCustomPopup = true;
    },
    closeOnlineCustomPopup() {
      this.onlinePopupOpened = false;
      this.showOnlineCustomPopup = false;
    },
  },
  created() {
    self = this;
    self.getMerchantInfoDetail();
    self.getDepositQAListEvent();
    self.getCSList();
  },
  mounted() {
    this.updateTime(); // Call updateTime() when the component is mounted
    this.intervalId = setInterval(this.updateTime, 1000); // Update time every second
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  updated() {},
};
</script>
<style lang="less" scoped>
.iframe-onlineCustom {
  width: 100%;
  height: calc(100% - 0px);
  min-height: calc(100% - 0px);
}
.online-cs-info {
  --f7-page-toolbar-bottom-offset: 0;
  .qaimg {
    width: 95%;
    margin: auto;
    border-radius: 5px;
    text-align: center;
    margin-top: 10px;
    img {
      border-radius: 5px;
      max-width: 100%;
      max-height: 100%;
      width: 100px;
      height: auto;
      min-width: 100%;
      min-height: 100%;
    }
  }

  .accordion-list {
    //background-color: #EEEEEE;
    margin-top: 20px;
    border-radius: 5px;
    width: 100%;
    //margin: auto;
    .accordion-item {
      border-bottom: 1px solid #f0f0f0;
      .col-title {
        position: relative;
        .help {
          position: absolute;
          left: 0px;
          border-radius: 5px;
          width: 19px;
          height: auto;
          vertical-align: middle;
        }
        .s-title {
          width: 94%;
          margin-left: 15px;
          margin-top: -1px;
          font-size: 15px;
          color: #464646;
          text-decoration: underline;
          word-break: break-all;
          word-wrap: break-word;
        }
        .accordion-item-content {
          .ans {
            width: 100%;
            margin-left: 10px 10px 10px 10px;
            // word-break: break-all;
            // word-wrap: break-word;
          }
        }
      }
    }
  }
  .curr-time {
    text-align: center;
    margin-top: 5px;
    font-size: 15px;
    color: #1168b5;
  }
  .button-save {
    margin: 10px 0px;

    ul {
      background: #9C865A;
      li {
        a {
          color: #fff;
        }
      }
    }
  }
}
.page-content-cs {
  .item-content {
    position: relative;
    .img-ico {
      position: absolute;
      left: -15px;
      height: 32px;
    }
    .go-ico {
      font-size: 18px;
      color: #d1d1d1;
    }
    .title {
      padding-left: 26px;
    }
  }
}
</style>
