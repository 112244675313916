<template>
  <f7-page no-toolbar no-swipeback name="daily-task-search" class="page-daily-task-search" @page:beforeremove="onPageBeforeRemove">
    <f7-navbar :title="$t('v1_dts_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-search">
      <div class="list inline-labels list-withdraw">
        <ul>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("v1_dts_0002") }}:</div>
                <div class="item-input-wrap">
                  <input type="text" readonly class="input-title" :value="$t('v1_dts_0003')" />
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("v1_dts_0004") }}:</div>
                <div class="item-input-wrap input-picker">
                  <input type="text" readonly="readonly" id="picker-activeType" :value="$t('common_0005')" />
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("v1_dts_0005") }}:</div>
                <div class="item-input-wrap input-picker">
                  <input type="text" readonly="readonly" id="picker-verifyStatus" :value="$t('common_0005')" />
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("v1_dts_0006") }}:</div>
                <div class="item-input-wrap">
                  <input type="text" id="dateF" readonly />
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("v1_Dts_0007") }}:</div>
                <div class="item-input-wrap">
                  <input type="text" id="dateT" readonly />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </f7-block>
    <div class="list inset button-search">
      <ul>
        <li>
          <a href="#" class="list-button color-black" @click="searchSubmit">{{ $t("common_0028") }}</a>
        </li>
      </ul>
    </div>
  </f7-page>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  props: {},
  data() {
    return {
      queryParam: {
        BeginDate: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        EndDate: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        ActivityType: "0",
        VerifyStatus: "0",
      },
    };
  },
  watch: {},
  computed: {
    dateF() {
      let date1 = new Date();
      let date2 = new Date(date1);
      date2.setDate(date1.getDate() - 8);
      let year = date2.getFullYear();
      let month = date2.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = date2.getDate();
      return `${year}-${month}-${day} 00:00:00`;
    },
  },
  methods: {
    pickerActiveType() {
      const self = this;
      let keys = ["0", "1", "2", "3", "4", "5", "6"];
      let values = [this.$t("common_0005"), this.$t("v1_dts_0008"), this.$t("v1_dts_0009"), this.$t("v1_dts_0010"), this.$t("v1_dts_0011"), this.$t("v1_dts_0012"), this.$t("v1_dts_0013")];
      self.pickerAvtive = self.$f7.picker.create({
        inputEl: "#picker-activeType",
        rotateEffect: true,
        routableModals: false,
        formatValue(values, displayValues) {
          return displayValues[0];
        },
        cols: [
          {
            textAlign: "left",
            values: keys,
            displayValues: values,
            onChange(picker, value, displayValue) {},
          },
        ],
        on: {
          change(picker, values, displayValues) {
            self.queryParam.ActivityType = values[0];
          },
        },
      });
    },
    pickerVerifyStatus() {
      const self = this;
      let keys = ["0", "1", "3", "4"];
      let values = [this.$t("common_0005"), this.$t("v1_dts_0014"), this.$t("v1_dts_0015"), this.$t("v1_dts_0016")];
      self.pickerVerify = self.$f7.picker.create({
        inputEl: "#picker-verifyStatus",
        rotateEffect: true,
        routableModals: false,
        formatValue(values, displayValues) {
          return displayValues[0];
        },
        cols: [
          {
            textAlign: "left",
            values: keys,
            displayValues: values,
            onChange(picker, value, displayValue) {},
          },
        ],
        on: {
          change(picker, values, displayValues) {
            self.queryParam.VerifyStatus = values[0];
          },
        },
      });
    },
    searchSubmit() {
      console.log(this.queryParam);
      if (new Date(this.queryParam.BeginDate).getTime() > new Date(this.queryParam.EndDate).getTime()) {
        this.$f7.dialog.alert(this.$t("v1_dts_0017"), this.$t("common_0004"));
        return false;
      }
      this.$f7router.navigate(`/member-center/daily-task-review/${JSON.stringify(this.queryParam)}/`);
    },
    onPageBeforeRemove() {
      this.pickerAvtive.destroy();
      this.pickerVerify.destroy();
    },
    initPickerDateF() {
      // Inline date-time
      const self = this;
      const app = self.$f7;
      let date1 = new Date();
      const today = new Date(date1);
      today.setDate(date1.getDate() - 8);
      const month = today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1;
      self.pickerInline = app.picker.create({
        inputEl: "#dateF",
        rotateEffect: true,
        routableModals: false,
        value: [today.getFullYear(), month, today.getDate() < 10 ? `0${today.getDate()}` : today.getDate(), "00", "00", "00"],
        formatValue(values, displayValues) {
          let year = values[0];
          let month = values[1];
          let day = values[2];
          return `${year}-${month}-${day} ${values[3]}:${values[4]}:${values[5]}`;
        },
        cols: [
          // Years
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 2010; i <= 2088; i += 1) {
                arr.push(i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: "-",
          },
          // Months
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 1; i <= 12; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: "-",
          },
          // Days
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 1; i <= 31; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          // Hours
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 23; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: ":",
          },
          // Minutes
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: ":",
          },
          // second
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
        ],
        on: {
          change(picker, values, displayValues) {
            const daysInMonth = new Date(picker.value[0], picker.value[1] * 1 + 1, 0).getDate();
            if (values[2] > daysInMonth) {
              //picker.cols[2].setValue(daysInMonth);
            }

            self.queryParam.BeginDate = `${values[0]}-${values[1]}-${values[2]} ${values[3]}:${values[4]}:${values[5]}`;
          },
        },
      });
    },
    initPickerDateT() {
      // Inline date-time
      const self = this;
      const app = self.$f7;
      const today = new Date();
      const month = today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1;
      self.pickerInline = app.picker.create({
        inputEl: "#dateT",
        rotateEffect: true,
        routableModals: false,
        value: [today.getFullYear(), month, today.getDate(), "23", "59", "59"],
        formatValue(values, displayValues) {
          let year = values[0];
          let month = values[1];
          let day = values[2];
          return `${year}-${month}-${day} ${values[3]}:${values[4]}:${values[5]}`;
        },
        cols: [
          // Years
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 2010; i <= 2088; i += 1) {
                arr.push(i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: "-",
          },
          // Months
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 1; i <= 12; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: "-",
          },
          // Days
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 1; i <= 31; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          // Hours
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 23; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: ":",
          },
          // Minutes
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: ":",
          },
          // second
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
        ],
        on: {
          change(picker, values, displayValues) {
            const daysInMonth = new Date(picker.value[0], picker.value[1] * 1 + 1, 0).getDate();
            if (values[2] > daysInMonth) {
              //picker.cols[2].setValue(daysInMonth);
            }
            self.queryParam.EndDate = `${values[0]}-${values[1]}-${values[2]} ${values[3]}:${values[4]}:${values[5]}`;
          },
        },
      });
    },
  },
  created() {
    this.queryParam.BeginDate = this.dateF;
  },
  mounted() {
    this.initPickerDateF();
    this.initPickerDateT();
    this.pickerActiveType();
    this.pickerVerifyStatus();
  },
};
</script>
<style lang="less" scoped>
.page-daily-task-search {
  .block-search {
    margin: 15px 10px;
    padding: 0;
    .list {
      margin: 0;
      ul {
        background-color: transparent;
        .item-input {
          .item-inner {
            .item-title {
              padding-top: 8px;
              width: 25%;
            }
            .input-picker:before {
              font-family: framework7-core-icons;
              font-weight: 400;
              font-style: normal;
              line-height: 1;
              letter-spacing: normal;
              text-transform: none;
              white-space: nowrap;
              word-wrap: normal;
              direction: ltr;
              -webkit-font-smoothing: antialiased;
              text-rendering: optimizeLegibility;
              -moz-osx-font-smoothing: grayscale;
              -moz-font-feature-settings: "liga";
              font-feature-settings: "liga";
              text-align: center;
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 50%;
              width: 8px;
              height: 14px;
              margin-top: -7px;
              font-size: 20px;
              font-size: var(--f7-list-chevron-icon-font-size);
              line-height: 14px;
              color: #c7c7cc;
              color: var(--f7-list-chevron-icon-color);
              pointer-events: none;
              right: calc(var(--f7-list-item-padding-horizontal) + 0px);
              right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right));
              content: "chevron_down";
            }
            input {
              color: #464646;
              font-size: 14px;
            }
            input.input-title {
              color: #464646;
              font-size: 16px;
            }
          }
          .item-inner:after {
            height: 0;
          }
        }
        .smart-select {
          .item-content {
            .item-inner {
              .item-after {
                width: 70%;
                color: #464646;
                font-size: 14px;
              }
            }
            .item-inner:after {
              height: 0;
            }
          }
        }
        .smart-select.active-state {
          background-color: transparent;
        }
        .item-title {
          color: #464646;
          font-size: 16px;
        }
      }
      ul:before,
      ul:after {
        height: 0;
      }
    }
  }
  .button-search {
    margin: 10px 12px;

    ul {
      background: #9C865A;
      li {
        a {
          color: #fff;
        }
      }
    }
  }
}
</style>
