<template>
  <f7-page no-toolbar no-swipeback name="vip-club" class="page-vip-club">
    <f7-navbar :title="$t('vip_club_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-banner">
      <img class="vip-banner" :src="`../../../static/images/yt999/vip_html/vip-banner_${$f7.params.currLang}.jpg`" :onerror="`this.onerror=''; src='../../../static/images/yt999/vip_html/vip-banner_enUS.jpg'`" />
    </f7-block>
    <div class="buttons-tab buttons-club">
      <f7-link tab-link="#tab-vip-advantage" tab-link-active :text="$t('vip_club_0002')"></f7-link>
      <f7-link tab-link="#tab-vip-conditions" :text="$t('vip_club_0003')"></f7-link>
      <f7-link tab-link="#tab-vip-privilege" :text="$t('vip_club_0004')"></f7-link>
      <!-- <f7-link tab-link="#tab-vip-description" text="特权说明"></f7-link> -->
    </div>
    <f7-tabs class="tabs-vip-club">
      <f7-tab id="tab-vip-advantage" class="page-content" tab-active>
        <f7-block class="block-advantage">
          <!-- <div class="advantage-title"></div> -->
          <div class="advantage-content">
            <div class="content-text">
              <p>
                {{ $t("vip_club_0023", [$f7.params.name]) }}
                <span class="yellow">VIP0、</span>
                <span class="yellow">VIP1、</span>
                <span class="yellow">VIP2、</span>
                <span class="yellow">VIP3、</span>
                <span class="yellow">VIP4、</span>
                <span class="yellow">VIP5、</span>
                <span class="yellow">VIP6、</span>
                <span class="yellow">VIP7、</span>
                <span class="yellow">VIP8、</span>
                <span class="yellow">VIP9、</span>
                <span class="yellow">VIP10、</span>
                {{ $t("vip_club_0024") }}
              </p>
            </div>
            <div class="content-img">
              <img :src="`../../../static/images/yt999/vip_html/menber-advantage-ad_${$f7.params.currLang}.png`" :onerror="`this.error='';src='../../../static/images/yt999/vip_html/menber-advantage-ad_enUS.png';`" width="100%" />
            </div>
          </div>
        </f7-block>
      </f7-tab>
      <f7-tab id="tab-vip-conditions" class="page-content">
        <f7-block class="block-conditions">
          <!-- <div class="conditions-title">
            <img
              src="../../../static/images/yt999/vip_html/mamber-condition.png"
              width="100%"
              class="lazy lazy-fade-in"
            >
          </div> -->
          <!-- <div class="conditions-content" :class="['conditions-content-' + item.code]" v-for="item of LANGLIST" :key="item.key"> -->
          <div class="conditions-content">
            <f7-row no-gap>
              <f7-col width="50" v-for="level of VIP_LEVEL_COUNT" :class="$f7.params.currLang + '-' + level" :key="$f7.params.currLang + '-' + level">
                <img :src="`../../../static/images/yt999/vip_html/vip${level - 1}_${$f7.params.currLang}.png`" :onerror="`this.onerror=''; src='../../../static/images/yt999/vip_html/vip${level}_enUS.png'`" width="100%" class="lazy lazy-fade-in" />
              </f7-col>
            </f7-row>
            <div class="content-remark">{{ $t("vip_club_0006") }}</div>
          </div>
          <div class="conditions-footer">
            <div class="footer-title">{{ $t("deposit_0024") }}:</div>
            <div class="footer-content">
              <p>{{ $t("vip_club_0007") }}</p>
              <p>{{ $t("vip_club_0008") }}</p>
              <p>{{ $t("vip_club_0009", [$f7.params.name]) }}</p>
            </div>
          </div>
        </f7-block>
      </f7-tab>
      <f7-tab id="tab-vip-privilege" class="page-content">
        <f7-block class="block-privilege">
          <!-- <div class="privilege-title">
            <img
              src="../../../static/images/yt999/vip_html/vip-privileges.png"
              width="100%"
              class="lazy lazy-fade-in"
            >
          </div> -->
          <div class="privilege-content">
            <img :src="`../../../static/images/yt999/vip_html/vip-privileges-bg_${$f7.params.currLang}.png`" :onerror="`this.onerror=''; src='../../../static/images/yt999/vip_html/vip-privileges-bg_enUS.png'`" width="100%" />
          </div>
        </f7-block>
      </f7-tab>
      <f7-tab id="tab-vip-description" class="page-content">
        <f7-block class="block-description">
          <!-- <div class="description-title">
            <img
              src="../../../static/images/yt999/vip_html/vip-Privilegethat.png"
              width="100%"
              class="lazy lazy-fade-in"
            >
          </div> -->
          <div class="description-content">
            <div class="content-title">
              <div>
                <img src="../../../static/images/yt999/vip_html/vip-freeJetton.png" class="lazy lazy-fade-in" />
              </div>
              <p>{{ $t("vip_club_0010") }}</p>
            </div>
            <div class="content-levels">
              <table>
                <thead>
                  <tr>
                    <th>{{ $t("vip_club_0011") }}</th>
                    <th>{{ $t("vip_club_0012") }}</th>
                    <th>{{ $t("vip_club_0011") }}</th>
                    <th>{{ $t("vip_club_0012") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>VIP0</td>
                    <td>0</td>
                    <td>VIP1</td>
                    <td>10000</td>
                  </tr>
                  <tr>
                    <td>VIP2</td>
                    <td>80000</td>
                    <td>VIP3</td>
                    <td>200000</td>
                  </tr>
                  <tr>
                    <td>VIP4</td>
                    <td>500000</td>
                    <td>VIP5</td>
                    <td>1000000</td>
                  </tr>
                  <tr>
                    <td>VIP6</td>
                    <td>2000000</td>
                    <td>VIP7</td>
                    <td>5000000</td>
                  </tr>
                  <tr>
                    <td>VIP8</td>
                    <td>10000000</td>
                    <td>VIP9</td>
                    <td>20000000</td>
                  </tr>
                  <tr>
                    <td>VIP10</td>
                    <td>50000000</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="content-privilege">
              <f7-row no-gap>
                <f7-col width="50">
                  <div>{{ $t("vip_club_0013") }}</div>
                  <p>{{ $t("vip_club_0014", [$f7.params.name]) }}</p>
                </f7-col>
                <f7-col width="50">
                  <div>{{ $t("vip_club_0015") }}</div>
                  <p>{{ $t("vip_club_0016") }}</p>
                </f7-col>
                <f7-col width="50">
                  <div>{{ $t("vip_club_0017") }}</div>
                  <p>{{ $t("vip_club_0018", [$f7.params.name]) }}</p>
                </f7-col>
                <f7-col width="50">
                  <div>{{ $t("vip_club_0019") }}</div>
                  <p>{{ $t("vip_club_0020", [$f7.params.name]) }}</p>
                </f7-col>
                <f7-col width="50">
                  <div>{{ $t("vip_club_0021") }}</div>
                  <p>{{ $t("vip_club_0022") }}</p>
                </f7-col>
              </f7-row>
            </div>
          </div>
        </f7-block>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import { LANGLIST } from "../../../locales/index";
export default {
  components: {},
  props: {},
  data() {
    return {
      LANGLIST,
      VIP_LEVEL_COUNT: 11,
    };
  },
  watch: {},
  computed: {},
  methods: {
    onError(e) {
      console.log(e);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.page-vip-club {
  --f7-page-toolbar-bottom-offset: 0px;
  .block-banner {
    margin: 0;
    padding: 0;
    .vip-banner {
      width: 100%;
      height: 140px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .buttons-club {
    height: 42px;
    width: var(--f7-width-size-pt100);
    display: var(--f7-display-flex);
    justify-content: var(--f7-text-center);
    box-sizing: var(--f7-border-box);
    align-items: var(--f7-text-center);
    align-content: var(--f7-text-center);
    overflow: var(--f7-hidden);
    // background-color: #0c1939;
    // border-top: 1px solid #0534a0;
    // border-bottom: 1px solid #0534a0;

    a.tab-link {
      width: var(--f7-width-size-pt100);
      height: var(--f7-height-size-pt100);
      box-sizing: var(--f7-border-box);
      display: var(--f7-display-flex);
      justify-content: var(--f7-text-center);
      align-items: var(--f7-text-center);
      flex-direction: var(--f7-flex-direction);
      text-transform: var(--f7-tabbar-link-text-transform);
      font-weight: var(--f7-tabbar-link-font-weight);
      letter-spacing: var(--f7-tabbar-link-letter-spacing);
      overflow: var(--f7-hidden);
      color: #808080;
      font-size: var(--f7-px-16);
    }

    a.tab-link.tab-link-active {
      color: #464646;
      border-bottom: 1px solid;
      // background: url(../../../static/images/yt999/about_nav.png) no-repeat 0px
      //   0px;
      // background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
    }
  }
  .tabs-vip-club {
    position: relative;
    overflow: hidden scroll;
    min-height: calc(100% - 185px);
    height: calc(100% - 185px);
    .tab {
      padding-top: 0;
      .block-advantage {
        border: 1px solid #f4f7f9;
        padding: 30px 10px 20px;
        font-size: 14px;
        color: #464646;
        background: #edf3f5;
        margin: 30px 10px 0;
        border-radius: 4px;
        .advantage-title {
          position: absolute;
          width: 250px;
          height: 50px;
          left: 0;
          top: -15px;
          right: 0;
          bottom: 0;
          margin: 0 auto;
          background: url(../../../static/images/yt999/vip_html/member-advantage-banner.png) no-repeat;
          background-size: 100%;
        }
        .advantage-content {
          .content-text {
            p {
              line-height: 25px;
              .yellow {
                color: #0084ff;
              }
            }
          }
        }
      }
      .block-conditions {
        margin: 10px 0;
        padding: 0 8px;
        .conditions-content {
          .row.no-gap {
            --f7-grid-gap: 8px;
            .col-50 {
              margin-bottom: 2px;
            }
          }
          .content-remark {
            padding: 8px 15px;
            background-color: #e2e2e2;
            color: #464646;
            margin: 10px 0;
          }
        }
        .conditions-footer {
          .footer-title {
            height: 45px;
            line-height: 45px;
            font-weight: bold;
            font-size: 16px;
            // background: url(../../../static/images/yt999/vip_html/vip-upgradePrompt-tip.png)
            //   center left no-repeat;
          }
          .footer-content {
            p {
              font-size: 14px;
              margin: 5px 0;
              color: #464646;
            }
          }
        }
      }
      .block-privilege {
        margin: 10px 0;
        padding: 0 8px;
      }
      .block-description {
        margin: 10px 0;
        padding: 0 8px;
        .description-content {
          .content-title {
            margin-top: 10px;
            p {
              margin: 0;
              color: #acb2d6;
            }
          }
          .content-levels {
            margin-top: 15px;
            height: 185px;
            background: url(../../../static/images/yt999/vip_html/vip-levels-bg.png) no-repeat;
            background-size: 100% 100%;
            table {
              width: 100%;
              color: #ffffff;
              border-spacing: 0;
              border-collapse: collapse;
              th,
              td {
                padding: 0;
                height: 30px;
                line-height: 31px;
                text-align: center;
              }
            }
          }
          .content-privilege {
            margin: 10px 0;
            .row.no-gap {
              --f7-grid-gap: 8px;
              .col-50 {
                padding: 5px 10px;
                margin-bottom: 10px;
                height: 110px;
                div {
                  background: -webkit-linear-gradient(left, #c18d3a, #f9e19b);
                  background: -o-linear-gradient(right, #c18d3a, #f9e19b);
                  background: -moz-linear-gradient(right, #c18d3a, #f9e19b);
                  background: linear-gradient(to right, #c18d3a, #f9e19b);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  color: #f1d49a;
                  font-size: 18px;
                  font-weight: bold;
                }
                p {
                  color: #d0d6ee;
                  margin: 3px 0;
                }
              }
              .col-50:nth-of-type(1) {
                background: url(../../../static/images/yt999/vip_html/vip-privilege-1.png) no-repeat;
                background-size: 100% 100%;
              }
              .col-50:nth-of-type(2) {
                background: url(../../../static/images/yt999/vip_html/vip-privilege-2.png) no-repeat;
                background-size: 100% 100%;
              }
              .col-50:nth-of-type(3) {
                background: url(../../../static/images/yt999/vip_html/vip-privilege-3.png) no-repeat;
                background-size: 100% 100%;
              }
              .col-50:nth-of-type(4) {
                background: url(../../../static/images/yt999/vip_html/vip-privilege-4.png) no-repeat;
                background-size: 100% 100%;
              }
              .col-50:nth-of-type(5) {
                background: url(../../../static/images/yt999/vip_html/vip-privilege-5.png) no-repeat;
                background-size: 100% 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
