<template>
  <f7-page no-toolbar no-swipeback name="bank-info" class="page-bank-info">
    <f7-navbar :no-hariline="true" innerClass="inner-bankInfo">
      <f7-nav-left>
        <f7-link @click="back">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t(isBinding ? 'bank_info_0001' : 'change_security_pwd_0016') }}</f7-nav-title>
    </f7-navbar>
    <template v-if="showInfo==1">
      <changeSecurityPwd @custom-event="handleCustomEvent"></changeSecurityPwd>
    </template>
    <template v-else-if="showInfo==2">
      <div class="buttons-tab buttons-deposit-bank-info">
        <f7-link tab-link="#tab-one" tab-link-active :text="$t('bank_info_0025')"></f7-link>
        <f7-link tab-link="#tab-two" :text="$t('bank_info_0026')"></f7-link>
      </div>
      <f7-tabs>
        <f7-tab  id="tab-one"  tab-active>
          <template>
            <f7-block-title class="block-bank-title text-center">*{{ $t("bank_info_0007") }}*</f7-block-title>
            <f7-block class="block-bank-info">
              <div class="form">
                <div class="form-item">
                  <div class="item-inner">
                    <div class="item-title item-label">{{ $t("bank_info_0008") }}</div>
                    <div class="item-input-wrap">
                      <input type="text" :placeholder="$t('bank_info_0009')" v-model="bankInfoForm.SureName" />
                    </div>
                  </div>
                </div>
                <div class="form-item">
                  <div class="item-inner">
                    <div class="item-title item-label">{{ $t("bank_info_0010") }}</div>
                    <div class="item-input-wrap">
                      <input type="text" :placeholder="$t('bank_info_0011')" v-model="bankInfoForm.BankAccountNo" />
                    </div>
                  </div>
                </div>
                <div class="form-item" v-show="bankInfoForm.BankName != 'Alipay'">
                  <div class="item-inner">
                    <div class="item-title item-label">{{ $t("bank_info_0012") }}</div>
                    <div class="item-input-wrap">
                      <input type="text" :placeholder="$t('bank_info_0013')" v-model="bankInfoForm.BankAddress" />
                    </div>
                  </div>
                </div>
                <!-- <div class="form-item">
                  <div class="item-inner">
                    <div class="item-title item-label ">{{ $t('member_info_0007') }}</div>
                    <div class="item-input-wrap">
                      <input type="text" :placeholder="$t('member_info_0008')" v-model="bankInfoForm.Mobile">
                    </div>
                  </div>
                </div> -->
                <div class="form-item">
                  <a class="smart-select smart-select-init" data-open-in="sheet">
                    <select v-model="bankInfoForm.BankName">
                      <option v-for="item in bankList" :key="item[0]" :value="item[0]">{{ item[1] }}</option>
                    </select>
                    <div class="item-content">
                      <div class="item-inner">
                        <div class="item-title">{{ $t("bank_info_0014") }}</div>
                        <!-- <div class="item-after item-input-wrap">{{ bankList[0] ? bankList[0][1] : '' }}</div> -->
                        <div class="item-after item-input-wrap max"></div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </f7-block>
            <div class="list inset button-save">
              <ul>
                <li>
                  <a href="#" class="list-button button-single" @click="bankInfoSubmit">{{ $t("member_info_0017") }}</a>
                </li>
              </ul>
              <p>{{ $t("common_0004") }}：</p>
              <p style="font-size: 15px">{{ $t("bank_info_0015") }}</p>
              <!-- <p style="font-size:15px;">{{ $t('bank_info_0016') }}</p>
              <p style="font-size:15px;">{{ $t('bank_info_0017') }}</p> -->
            </div>
          </template>
        </f7-tab>
        <f7-tab  id="tab-two" >
          <template v-if="memberBankCardList.length > 0">
            <f7-block v-for="item in memberBankCardList" :key="item.MemberBankCardID" class="block-bank-info">
              <div class="card bank-card">
                <div class="card-header">
                  <div class="card-avatar"><img src="../../../static/images/yt999/banklogo/bankcode.png" width="32" height="32" /></div>
                  <div class="card-name">{{ item.BankName ? item.BankName : item.BankAddress }}</div>
                </div>
                <div class="card-content">
                  <div class="card-info text-26">{{ item.BankAccountNo | fmtBankNumber }}</div>
                  <div class="card-info card-address text-16">
                    <span>※</span>
                    <span>{{ item.HolderName ? item.HolderName : this.$t("bank_info_0002") }}</span>
                  </div>
                  <div class="card-info card-address text-16">
                    <span>※</span>
                    <span>{{ item.BankAddress }}</span>
                  </div>
                  <!-- <div class="card-info card-address text-16">
                    <span>※</span>
                    <span>{{item.Mobile|formatPhoneNumber}}</span>
                  </div> -->
                </div>
                <!-- <div class="card-footer">
                  <f7-block>
                    <f7-row>
                      <f7-col>
                        <f7-button class="cpy" fill v-clipboard:copy="item.HolderName" v-clipboard:success="onCopy" v-clipboard:error="onError">{{ $t('bank_info_0004') }}</f7-button>
                      </f7-col>
                      <f7-col>
                        <f7-button class="cpy" fill v-clipboard:copy="item.BankAccountNo" v-clipboard:success="onCopy" v-clipboard:error="onError">{{ $t('bank_info_0005') }}</f7-button>
                      </f7-col>
                      <f7-col>
                        <f7-button class="cpy" fill v-clipboard:copy="item.BankAddress" v-clipboard:success="onCopy" v-clipboard:error="onError">{{ $t('bank_info_0006') }}</f7-button>
                      </f7-col>
                    </f7-row>
                  </f7-block>
                </div> -->
              </div>
            </f7-block>
          </template>
        </f7-tab>
      </f7-tabs>
  </template>
  </f7-page>
</template>
<script>
import { getUserInfo, getBlankList, bankbind, checkSecurePwdStatus } from "../../../axios/api";
import changeSecurityPwd from "../../template/change-security-pwd";

let self;
export default {
  components: {changeSecurityPwd},
  props: {},
  data() {
    return {
      userInfo: {},
      bindBankInfoStatus: false,
      bankList: [],
      bankInfoForm: {
        BankAccountNo: "",
        SureName: "",
        BankAddress: "",
        BankName: "",
        Mobile: "",
      },
      memberBankCardList: [],
      SureNameFlag: false,
      showInfo: -1,
      isBinding: false,

    };
  },
  watch: {},
  computed: {},
  methods: {
    getUserInfoEvent() {
      getUserInfo().then((data) => {
        if (data.Code === "NoError") {
          self.memberBankCardList = data.Data.MemberBankCardList;
          if (data.Data.BankAccountNo) {
            self.bindBankInfoStatus = true;
          }
          if (data.Data.SureName === "") {
            self.SureNameFlag = true;
          }
          self.userInfo = data.Data;
        }
      });
    },
    handleCustomEvent(value){
      console.log(value)
      this.showInfo = 2;
      this.isBinding = true;
    },
    onCopy(e) {
      if (e.text) {
        let copyLoading = self.$f7.dialog
          .create({
            title: "",
            text: this.$t("common_0003"),
            cssClass: "dialog-preloadgame",
          })
          .open();

        setTimeout(() => {
          copyLoading.close();
        }, 1000);
      }
    },
    onError(e) {
      console.log(e);
    },
    getBankListEvent() {
      const self = this;
      getBlankList().then((data) => {
        if (data.Code === "NoError") {
          self.bankList = data.Data;
          // if (self.bankList.length > 0) {
          //   self.bankInfoForm.BankName = self.bankList[0][0];
          // }
        }
      });
    },
    bankInfoSubmit() {
      let sureName = /^[A-Za-zÀ-ÖØ-öø-ÿ' -]+$/; //真实姓名验证表达式
      if (self.userInfo.SureName === "") {
        //无姓名则需要输入验证
        if (!self.bankInfoForm.SureName) {
          self.$f7.dialog.alert(this.$t("bank_info_0018"), this.$t("common_0004"));
          return false;
        }
      }
      // if (!sureName.test(self.bankInfoForm.SureName) && self.bankInfoForm.SureName)
      // {
      //       self.$f7.dialog.alert(this.$t('bank_info_0019'), this.$t('common_0004'));
      //       return false;
      // }
      if (!self.bankInfoForm.BankAccountNo) {
        self.$f7.dialog.alert(this.$t("bank_info_0020"), this.$t("common_0004"));
        return false;
      }

      if (!self.bankInfoForm.BankAddress) {
        self.$f7.dialog.alert(this.$t("bank_info_0021"), this.$t("common_0004"));
        return false;
      }
      self.bankInfoForm.Mobile = "--";
      // if (!self.bankInfoForm.Mobile) {
      //   self.$f7.dialog.alert(this.$t('member_info_0008'), this.$t('common_0004'));
      //   return false;
      // }
      // let phoneNumberCheck = /^\+?[0-9]{1,4}(?:\s?[0-9]{1,4})*$/;
      // if (!phoneNumberCheck.test(self.bankInfoForm.Mobile)){
      //   self.$f7.dialog.alert(this.$t('member_info_0019'), this.$t('common_0004'));
      //   return false;
      // }
      if (!self.bankInfoForm.BankName) {
        self.$f7.dialog.alert(this.$t("bank_info_0022"), this.$t("common_0004"));
        return false;
      }
      const param = { data: JSON.stringify(self.bankInfoForm) };
      bankbind(param).then((data) => {
        if (data.Code === "NoError") {
          self.$f7.dialog.alert(this.$t("bank_info_0023"), this.$t("common_0004"));
          self.getUserInfoEvent();
        } else {
          self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
        }
      });
    },
    back() {
      if (this.$f7route.params.Id == 1) {
        this.$f7router.back();
      } else {
        this.$f7router.navigate("/member-center/");
      }
    },
  },
  created() {
    self = this;
    this.getBankListEvent();
    this.getUserInfoEvent();
    checkSecurePwdStatus().then((data) => {
      if (data.Code === "NoError") {
        self.isBinding = data.Data.IsBinding;
        
        if (!data.Data.IsBinding) {
          self.$f7.dialog.alert(this.$t("bank_info_0024"), this.$t("common_0004"), function () {
            // self.$f7router.navigate("/member-center/change-security-pwd/");
          });
          this.showInfo = 1;
        }else{
          this.showInfo = 2;
        }
      } else {
        self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
      }
    });
  },
  mounted() {},
  updated() {},
};
</script>
<style lang="less" scoped>
.page-bank-info {
  --f7-page-toolbar-bottom-offset: 0;
  .bank-card {
    margin: 0px;
    background: linear-gradient(#352401, #9c865a);
    border-radius: 8px;
    color: #fff;

    .card-header {
      justify-content: flex-start;
      margin: 0 12px;
      padding-right: 0;
      padding-left: 0;
      border-bottom: 1px solid var(--f7-theme-color);

      .card-avatar {
        img {
          vertical-align: middle;
        }
      }

      .card-name,
      .card-accountname {
        margin: 0 10px;
        font-size: 16px;
        position: relative;
      }

      // .card-name:after {
      //   content: "";
      //   position: absolute;
      //   width: 1px;
      //   height: 15px;
      //   //background-color: #fff;
      //   right: -10px;
      //   top: 4px;
      //   display: block;
      // }
    }

    .card-header:after {
      height: 0;
    }

    .card-content {
      .card-info {
        margin: 6px 10px;
        padding: 5px 0;
      }

      .card-address {
        margin-top: -10px;
      }
    }

    .card-footer {
      padding: 15px 8px;

      .block {
        width: 100%;

        .button {
          background-color: #3569cf;
          color: #ffffff;
          font-size: 10px;
        }
      }
    }

    .card-footer:before {
      height: 0;
    }
  }
  .block-bank-title {
    color: #e42f2f;
    margin: 20px 0 10px;
  }
  .block-bank-info {
    margin: 10px 12px 12px;
    padding: 0;
    .max {
      max-width: 100%;
    }
    .form {
      .item-title {
        color: #464646;
      }
      .item-input-wrap {
        // width: 100%;
        background: #fafafa;
        border: 1px solid #1b2a3b10;
        padding: 10px;
        border-radius: 6px;
        margin: 2px 0 10px;
        min-height: 20px;
        input {
          width: 100%;
        }
      }
    }
  }
  .button-save {
    margin: 20px 12px;

    ul {
      background: #9C865A;
      li {
        a {
          color: #fff;
        }
      }
    }
  }
}


//顶部菜单用
.ios .page-bank-info .page-content .buttons-deposit-bank-info {
  // height: var(--f7-px-50);
  width: var(--f7-width-size-pt100);
  display: var(--f7-display-flex);
  justify-content: var(--f7-text-center);
  box-sizing: var(--f7-border-box);
  align-items: var(--f7-text-center);
  align-content: var(--f7-text-center);
  overflow: var(--f7-hidden);
}
.ios .page-bank-info .page-content .buttons-deposit-bank-info a.tab-link {
  width: var(--f7-width-size-pt100);
  height: var(--f7-height-size-pt100);
  box-sizing: var(--f7-border-box);
  display: var(--f7-display-flex);
  justify-content: var(--f7-text-center);
  align-items: var(--f7-text-center);
  flex-direction: var(--f7-flex-direction);
  text-transform: var(--f7-tabbar-link-text-transform);
  font-weight: var(--f7-tabbar-link-font-weight);
  letter-spacing: var(--f7-tabbar-link-letter-spacing);
  overflow: var(--f7-hidden);
  color: #808080;
}

.ios .page-bank-info .page-content .buttons-deposit-bank-info{
  // min-height: 40px;
  width: 90%!important;
  margin: 10px auto;
  border: 1px solid #9C865A;
  border-radius: 8px;
}
.ios .page-bank-info .page-content .buttons-deposit-bank-info a.tab-link{
  color: #1d1a1a;
  padding: 0px 5px;
  text-align: center;
}
.ios .page-bank-info .page-content .buttons-deposit-bank-info a.tab-link.tab-link-active{
  border: 0;
  background: #9C865A;
  color: #fff;
  padding: 5px;
}
.ios .page-bank-info .page-content .buttons-deposit-bank-info a.tab-link:last-child:after{
  width: 0;
  height: 0;
  display: none;
}
.ios .page-bank-info .page-content .buttons-deposit-bank-info a.tab-link i {
  margin-top: var(--f7-padding-size-2);
  font-size: var(--f7-px-32);
}
.ios .page-bank-info .page-content .buttons-deposit-bank-info a.tab-link span {
  font-size: var(--f7-px-14);
  margin-left: var(--f7-margin-size-0);
}
.ios .page-bank-info .page-content .buttons-deposit-bank-info a.tab-link:after {
  content: '';
  width: var(--f7-width-size-2);
  height: var(--f7-height-size-pt100);
  position: absolute;
  right: var(--f7-size-ps-0);
  top: var(--f7-size-ps-0);
  background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
}
.ios .page-bank-info .page-content .buttons-deposit-bank-info a.tab-link:last-child:after {
  height: var(--f7-height-size-0);
}
.ios .page-bank-info .page-content .buttons-deposit-bank-info a.tab-link.tab-link-active {
  color: #fff;
}
.ios .page-bank-info .page-content .buttons-deposit-bank-info a.tab-link.tab-link-active i.iconfont {
  color: var(--f7-color-blue-active);
}
//顶部菜单用
</style>
