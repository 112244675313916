<template>
  <f7-page no-toolbar no-swipeback name="point-center" class="page-point-center">
    <f7-navbar :title="$t('v1_pc_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-total-point">
      <f7-row class="row-total-point">
        <f7-col class="point-title">{{ $t("v1_pc_0002") }}：</f7-col>
        <f7-col class="text-right">{{ forPointData.CurrentBonusPoint }}{{ $t("v1_pc_0004") }}</f7-col>
      </f7-row>
      <f7-row class="row-total-point">
        <f7-col class="point-title">{{ $t("v1_pc_0003") }}：</f7-col>
        <f7-col class="text-right">{{ forPointData.TotalBonusPoint }}{{ $t("v1_pc_0004") }}</f7-col>
      </f7-row>
    </f7-block>
    <f7-block class="block-proportion">{{ $t("v1_pc_0005") }}</f7-block>
    <f7-block class="block-choose">
      <p>{{ $t("v1_pc_0006") }}</p>
      <div class="row no-gap row-choose">
        <div class="col-33" v-for="item in bonusPointList" :key="item.BonusPointGoodsID" :class="{ active: selectedPointId == item.BonusPointGoodsID }" @click="choosePoint(item)">
          <p>{{ item.BonusPointGoodsName }}</p>
          <p class="itme-point">{{ item.Price }}{{ $t("v1_pc_0007") }}</p>
        </div>
      </div>
    </f7-block>
    <div class="list inset button-point">
      <ul>
        <li>
          <a href="#" class="list-button color-black" @click="pointSubmit">{{ $t("v1_pc_0008") }}</a>
        </li>
      </ul>
    </div>
    <f7-block class="block-tip">
      <p class="tip-title">{{ $t("common_0004") }}：</p>
      <p>1.&nbsp;{{ $t("v1_pc_0009") }}</p>
      <p>2.&nbsp;{{ $t("v1_pc_0010") }}</p>
      <p>3.&nbsp;{{ $t("v1_pc_0011", [$f7.params.name]) }}</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { postForPoints, getbonuspointgoodslist, doBonuspointUse } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      forPointData: {},
      bonusPointList: [],
      selectedPointId: 0,
    };
  },
  watch: {},
  computed: {},
  methods: {
    postForPointsEvent() {
      const self = this;
      postForPoints().then((data) => {
        if (data.Code === "NoError") {
          self.forPointData = data.Data;
        }
      });
    },
    getbonuspointgoodslistEvent() {
      const self = this;
      getbonuspointgoodslist().then((data) => {
        if (data.Code === "NoError") {
          self.bonusPointList = data.Data;
        }
      });
    },
    pointSubmit() {
      const self = this;
      if (!self.selectedPointId) {
        self.$f7.dialog.alert(this.$t("v1_pc_0012"), this.$t("common_0004"));
        return false;
      }
      const param = {
        data: JSON.stringify({ BonusPointGoodsID: self.selectedPointId }),
      };
      doBonuspointUse(param).then((data) => {
        self.$f7.dialog.alert(data.Data, this.$t("common_0004"), () => {
          self.postForPointsEvent();
        });
      });
    },
    choosePoint(item) {
      this.selectedPointId = item.BonusPointGoodsID;
    },
  },
  created() {
    this.postForPointsEvent();
    this.getbonuspointgoodslistEvent();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.page-point-center {
  --f7-page-toolbar-bottom-offset: 0px;
  .block-total-point {
    margin: 8px 10px;
    padding: 10px;
    background: #e9ebef;
    border-radius: 4px;
    .row-total-point {
      padding: 8px 0;
      font-size: 16px;
      color: #224ec7;
      .point-title {
        color: #464646;
      }
    }
    .row-total-point:first-child {
      border-bottom: 1px solid #27418b;
    }
  }
  .block-proportion {
    margin: 0px 12px;
    padding: 2px 0 12px;
    border-bottom: 1px solid #1f448a;
    color: #8d99a9;
    font-size: 16px;
  }
  .block-choose {
    margin: 10px 12px;
    padding: 0;
    p {
      margin-bottom: 10px;
      font-size: 16px;
      color: var(--f7-theme-color);
    }
    .row-choose {
      .col-33 {
        text-align: center;
        color: #aabdcc;
        font-size: 16px;
        border: 1px solid #6882b7;
        border-radius: 5px;
        margin-bottom: 5px;
        p {
          margin: 10px 0;
          color: #464646;
        }
        .itme-point {
          color: #cb2e2e;
        }
      }
      .active {
        color: #fff;
        background: url(../../../static/images/yt999/active_bg.png) no-repeat;
        background-size: 100% 100%;
        border: 1px solid #ffc107;
        p {
          color: #fff;
        }
        .itme-point {
          color: #fff;
        }
      }
    }
  }
  .button-point {
    margin: 10px 8px;

    ul {
      background: #9C865A;
      li {
        a {
          color: #fff;
        }
      }
    }
  }
  .block-tip {
    margin: 20px 8px;
    padding: 0;
    p {
      color: #464646;
    }
    .tip-title {
      color: #464646;
    }
  }
}
</style>
